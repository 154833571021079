import { Box, SvgIcon } from "@material-ui/core";
import { View } from "react-native-web";
import _ from "lodash";
import { CenteredLoader } from "../../components/CenteredLoader";
import { translate } from "@ollie-sports/i18n";
import { useParams } from "react-router-dom";
import { useOrg } from "../../hooks/useOrg";
import { GENDERS, Org, TEAM_ROLES, Team__StaffTypes } from "@ollie-sports/models";
import { OrgMemberTableData } from "@ollie-sports/core";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "@material-ui/core";
import { Download, MoreHorizontal } from "react-feather";
import { AsyncFancyTable, AsyncFancyTableMethods } from "../../components/AsyncFancyTable";
import MailLayout, { useMailContext } from "../../components/Compose/MailLayout";
import { getDialogOptions } from "../../components/DialogOptions";
import { CoolMultiSelectInput } from "../../components/Inputs/CoolMultiSelectInput";
import { CoolTextInput } from "../../components/Inputs/CoolTextInput";
import { StyledText } from "../../components/StyledText";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { useOrgTeams } from "../../hooks/useOrgTeams";
import { getBifrost } from "../../services/bifrost.service";
import { usePersistentState } from "../../utils/usePersistentState";
import { PersistedComposeStoreState } from "../../components/Compose/constants";
import { createPersistedSynchronousStore } from "../../utils/createPersistedSynchronousStore";
import { LoadingIndicator, openLoadingIndicator } from "../../utils/openLoadingIndicator";
import { ActionButtonDropdown } from "../../components/ActionButtonDropdown";
import { prettyGender } from "../../utils/genderUtils";

export default function OrgMembers() {
  const params: any = useParams();
  const orgId = params.orgId;

  const { org } = useOrg({ orgId });

  const instanceId = "member-table-list-" + orgId;

  const store = useMemo(() => {
    return createPersistedSynchronousStore<PersistedComposeStoreState>({
      initialState: {
        selectedRecipientGroups: [],
        subject: "",
        body: "",
        attachments: []
      },
      namespace: instanceId,
      shouldBeClearedOnUpdates: true
    });
  }, [orgId]);

  return (
    <MailLayout instanceId="" store={store} fetchContextData={false} sendFromOrgContext={org}>
      <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
        <View style={{ flex: 1 }}>{!org ? <CenteredLoader /> : <OrgMembersInner org={org} />}</View>
      </Box>
    </MailLayout>
  );
}

function OrgMembersInner(p: { org: Org }) {
  const [selectedMembersByAccountIdOrPlayerBundleId, setSelectedMembersByAccountIdOrPlayerBundleId] = usePersistentState({
    initialValue: {} as Record<string, true>,
    key: "org-members-table-selected-players" + p.org.id
  });

  const mailContext = useMailContext();

  const numSelectedMembers = Object.keys(selectedMembersByAccountIdOrPlayerBundleId).length;

  const orgTeams = useOrgTeams({ orgId: p.org.id }) ?? [];

  const asyncFancyTableRef = useRef<AsyncFancyTableMethods<OrgMemberTableData>>(null);

  return (
    <View style={{ flex: 1, paddingBottom: 30 }}>
      <View style={{ flexDirection: "row" }}>
        <h1 className="text-2xl sm:text-4xl flex-1 mt-4">
          {translate({ defaultMessage: "{orgName} Members" }, { orgName: p.org.name })}
        </h1>
      </View>

      <div className="pb-8">
        <AsyncFancyTable
          methodsRef={asyncFancyTableRef}
          pagination={{ initialPageSize: 50, pageSizes: [50, 100, 150] }}
          dataCachingKey={"players-" + p.org.id}
          getRowKey={item => item.accountOrPlayerBundleId}
          selectRowOptions={{
            selectedItemsByKey: selectedMembersByAccountIdOrPlayerBundleId,
            onUpdate: newVal => {
              setSelectedMembersByAccountIdOrPlayerBundleId(newVal);
            },
            selectAllQuestionText: currData =>
              translate(
                {
                  defaultMessage:
                    "Select all {num} members? To select only the players on this page, select the first row, hold down the shift key, and then click the last row."
                },
                { num: currData.totalNumberOfItemsMatchingCriteria }
              )
          }}
          fetchItems={info =>
            getBifrost()
              .org__server__getOrgMemberTableData.fetchServer({
                orgId: p.org.id,
                searchTerm: info.filters?.search,
                selfAccountId: getCurrentUserAccountId(),
                offset: info.pagination.numItemsPerPage * (info.pagination.page - 1),
                sort: info.sort ?? [],
                limit: info.pagination.numItemsPerPage,
                roles: info.filters?.roles
                  ?.split("|")
                  .filter(r => Object.keys(TEAM_ROLES).includes(r) || Object.keys(Team__StaffTypes).includes(r)) as
                  | (TEAM_ROLES | Team__StaffTypes)[]
                  | undefined
              })
              .then(a => {
                return {
                  itemsToBeRendered: a.data.data,
                  totalNumberOfItemsMatchingCriteria: a.data.count
                };
              })
          }
          getRowOptions={(item: OrgMemberTableData) => {
            return {
              href: `/app/org/${p.org.id}/members/${item.accountOrPlayerBundleId}`
            };
          }}
          columns={{
            name: {
              label: translate.common.Name,
              getCell(item: OrgMemberTableData) {
                return `${item.firstName.trim()} ${item.lastName.trim()}`;
              },
              sortable: true
            },
            teams: {
              label: translate.common.Teams,
              getCell(item: OrgMemberTableData) {
                const teamsInThisOrg = orgTeams.filter(t => item.teamIds.includes(t.id));
                return (
                  <View>
                    {teamsInThisOrg.map(t => {
                      return <StyledText key={t.id}>{t.name}</StyledText>;
                    })}
                  </View>
                );
              },
              toExportCsvString(item) {
                const teamsInThisOrg = orgTeams.filter(t => item.teamIds.includes(t.id));
                return teamsInThisOrg.map(team => team.name).join(", ");
              }
            },
            roles: {
              label: translate.common.Roles,
              getCell(item: OrgMemberTableData) {
                const roles = _.compact([
                  item.isPlayer ? translate.common.Player : null,
                  item.isGuardian ? translate.common.Guardian : null,
                  item.isHeadCoach ? translate.common.HeadCoach : null,
                  item.isAssistantCoach ? translate.common.AssistantCoach : null,
                  item.isTeamAdmin ? translate.common.TeamAdmin : null,
                  item.isStaffMember ? translate.common.StaffMember : null
                ]);
                return (
                  <View>
                    {roles.map(role => {
                      return <StyledText key={role}>{role}</StyledText>;
                    })}
                  </View>
                );
              },
              toExportCsvString(item) {
                const roles = _.compact([
                  item.isPlayer ? translate.common.Player : null,
                  item.isGuardian ? translate.common.Guardian : null,
                  item.isHeadCoach ? translate.common.HeadCoach : null,
                  item.isAssistantCoach ? translate.common.AssistantCoach : null,
                  item.isTeamAdmin ? translate.common.TeamAdmin : null,
                  item.isStaffMember ? translate.common.StaffMember : null
                ]);
                return roles.filter(r => Boolean(r)).join(", ");
              }
            },
            gender: {
              label: translate({ defaultMessage: "M/F", description: "Male or female short" }),
              infoTooltip: translate({ defaultMessage: "The genders of the teams the player is assigned to." }),
              getCell: item => {
                const mfTeams = item.isPlayer ? item.teamGenders.filter(a => a !== GENDERS.mixed) : [];

                if (mfTeams.length) {
                  return _(mfTeams)
                    .uniq()
                    .map(a => prettyGender(a))
                    .value()
                    .join(", ");
                } else {
                  return "";
                }
              }
            },
            email: {
              label: translate.common.Email,
              getCell(item: OrgMemberTableData) {
                return <StyledText>{item.email ?? ""}</StyledText>;
              },
              sortable: true
            },
            phone_number: {
              label: translate.common.PhoneNumber,
              getCell(item: OrgMemberTableData) {
                return <StyledText>{item.phoneNumber ?? ""}</StyledText>;
              }
            }
          }}
          renderFiltersWrapper={filters => <div className="my-4 flex flex-row">{filters}</div>}
          renderFilters={{
            search: a => (
              <CoolTextInput
                showClearButton
                style={{ width: 300 }}
                value={a.valueImmediate}
                isLoading={a.value !== a.valueImmediate || a.isTableFetching}
                placeholder={translate({ defaultMessage: "Search by name, email, or team..." })}
                onChange={newVal => {
                  if (newVal) {
                    a.setValueDebounced(newVal, 500, { leading: false, trailing: true });
                  } else {
                    a.setValue("");
                  }
                }}
              />
            ),
            roles: a => (
              <>
                <CoolMultiSelectInput
                  value={_.compact(a.valueImmediate.split("|").map(a => getOptionForRoleOrStaffType(a)))}
                  placeholder={translate.common.Role}
                  onChange={newVal => {
                    a.setValue(newVal.map(a => a.value).join("|"));
                  }}
                  containerStyle={{ minWidth: 200 }}
                  containerClassName="ml-2 min-w-64"
                  options={_.compact([
                    getOptionForRoleOrStaffType(TEAM_ROLES.athlete),
                    getOptionForRoleOrStaffType(TEAM_ROLES.guardian),
                    getOptionForRoleOrStaffType(Team__StaffTypes.assistantCoach),
                    getOptionForRoleOrStaffType(Team__StaffTypes.headCoach),
                    getOptionForRoleOrStaffType(Team__StaffTypes.staffMember),
                    getOptionForRoleOrStaffType(Team__StaffTypes.teamAdmin),
                    getOptionForRoleOrStaffType(TEAM_ROLES.staff)
                  ])}
                />
                <ActionButtonDropdown
                  style={{ marginLeft: "auto" }}
                  actions={[
                    {
                      key: "download",
                      label: () =>
                        translate(
                          { defaultMessage: "Export {num} Records to CSV" },
                          { num: numSelectedMembers || asyncFancyTableRef.current?.getCurrentNumRecords() || 0 }
                        ),
                      value: "",
                      onClick: () => {
                        asyncFancyTableRef.current?.downloadCurrentDataToCSV(
                          "members.csv",
                          numSelectedMembers
                            ? {
                                filter: item => {
                                  return selectedMembersByAccountIdOrPlayerBundleId[item.accountOrPlayerBundleId];
                                }
                              }
                            : undefined
                        );
                      }
                    },
                    numSelectedMembers
                      ? {
                          key: "email",
                          label: () =>
                            translate(
                              { defaultMessage: "Email {num} Selected Members & Their Guardians" },
                              { num: numSelectedMembers }
                            ),
                          value: "",
                          onClick: () => {
                            mailContext.openMailComposer({
                              resetWithSelectedRecipients: [
                                {
                                  type: "arbitraryAccountOrPlayerBundleGrouping",
                                  accountIdsOrPlayerBundleIds: Object.keys(selectedMembersByAccountIdOrPlayerBundleId),
                                  lookupKey: Object.keys(selectedMembersByAccountIdOrPlayerBundleId).join(",")
                                }
                              ]
                            });
                          }
                        }
                      : (null as any),
                    mailContext.hasSelectedRecipients
                      ? {
                          key: "open",
                          label: () => translate({ defaultMessage: "Open Saved Email Draft" }),
                          value: "",
                          onClick: () => {
                            mailContext.openMailComposer();
                          }
                        }
                      : (null as any)
                  ].filter(Boolean)}
                >
                  <span style={{ fontWeight: "bold", marginRight: 6 }}>
                    {translate({ defaultMessage: "Actions" }, { num: numSelectedMembers })}
                  </span>
                  <SvgIcon style={{ paddingRight: 6 }}>
                    <MoreHorizontal />
                  </SvgIcon>
                </ActionButtonDropdown>
              </>
            )
          }}
          initialFilterValues={{
            search: "",
            roles: ""
          }}
        />
      </div>
    </View>
  );
}

function getOptionForRoleOrStaffType(roleOrStaffType: string) {
  return roleOrStaffType === TEAM_ROLES.athlete
    ? { value: TEAM_ROLES.athlete, label: translate.common.Player }
    : roleOrStaffType === TEAM_ROLES.guardian
    ? { value: TEAM_ROLES.guardian, label: translate.common.Guardian }
    : roleOrStaffType === TEAM_ROLES.staff
    ? { value: TEAM_ROLES.staff, label: translate({ defaultMessage: "All Staff" }) }
    : roleOrStaffType === Team__StaffTypes.assistantCoach
    ? { value: Team__StaffTypes.assistantCoach, label: translate.common.AssistantCoach }
    : roleOrStaffType === Team__StaffTypes.headCoach
    ? { value: Team__StaffTypes.headCoach, label: translate.common.HeadCoach }
    : roleOrStaffType === Team__StaffTypes.staffMember
    ? { value: Team__StaffTypes.staffMember, label: translate.common.StaffMember }
    : roleOrStaffType === Team__StaffTypes.teamAdmin
    ? { value: Team__StaffTypes.teamAdmin, label: translate.common.TeamAdmin }
    : null;
}
