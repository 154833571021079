import { translate } from "@ollie-sports/i18n";
import { PrettyCoolTextInputWithLabel, PrettyCoolTextAreaWithLabel, Form, FormRef } from "../../../components/Form";
import { CoolCheckboxInput } from "../../../components/Inputs/CoolCheckboxInput";
import { LINK_REGEX, EMAIL_REGEX } from "../../../utils/Regexes";
import { OrgSettings } from "@ollie-sports/models";
import { useImmutableState } from "../../../utils/useImmutableState";
import { useRef } from "react";
import { Button } from "@material-ui/core";
import { common__magicDeleteValue } from "@ollie-sports/core/dist/api";
import { getBifrost } from "../../../services/bifrost.service";
import { openErrorToast } from "../../../utils/openErrorToast";
import { InfoTooltipIcon } from "../../../components/InfoTooltip";
import { StyledButton } from "../../../components/StyledButton";
import { Prompt } from "react-router";
import { dequal } from "dequal";
import { useOrgSettings } from "../../../hooks/useOrgSettings";

export function OrgCampSettings(p: { orgId: string; initialOrgSettings: OrgSettings }) {
  const [orgSettings, setOrgSettings] = useImmutableState<OrgSettings>(p.initialOrgSettings);
  const { orgSettings: subscribedOrgSettings } = useOrgSettings({ orgId: p.orgId });
  const formRef = useRef<FormRef>(null);

  return (
    <Form ref={formRef}>
      {() => (
        <>
          <Prompt
            message={translate({ defaultMessage: "Are you sure you wish to navigate? You have unsaved changes." })}
            when={!dequal(subscribedOrgSettings, orgSettings)}
          />
          <PrettyCoolTextInputWithLabel
            label={translate({ defaultMessage: "Default Camp Reply Email" })}
            onChange={newVal => {
              setOrgSettings({
                campSettings: { ...orgSettings.campSettings, defaultReplyToEmailAddress: newVal?.toLowerCase() ?? "" }
              });
            }}
            infoTooltip={translate({
              defaultMessage:
                'The default "reply to" email address members see when receiving emails regarding camps, such as their camp registration receipt.'
            })}
            validate={v => {
              if (v) {
                if (!v.match(LINK_REGEX)) {
                  return translate({ defaultMessage: "Must be a valid email" });
                }
              }
              return "";
            }}
            inputProps={{ className: "sm:max-w-xs", placeholder: translate({ defaultMessage: "Enter Email Address" }) }}
            value={orgSettings.campSettings?.defaultReplyToEmailAddress ?? ""}
          />
          <PrettyCoolTextAreaWithLabel
            label={translate({ defaultMessage: "Camp Signup Confirmation Email" })}
            style={{ marginTop: 16 }}
            onChange={newVal => {
              setOrgSettings({ campSettings: { ...orgSettings.campSettings, confirmationEmailMessage: newVal } });
            }}
            infoTooltip={translate({
              defaultMessage: "The email sent to parents/guardians immediately after they successfully sign up for a camp."
            })}
            textAreaProps={{
              placeholder: translate({
                defaultMessage: "Type email message..."
              })
            }}
            value={`${orgSettings.campSettings?.confirmationEmailMessage ?? ""}`}
          />

          <CoolCheckboxInput
            style={{ marginTop: 16 }}
            label={translate({ defaultMessage: "Restrict Camp Visibility" })}
            infoTooltip={translate({
              defaultMessage:
                "When restricted, coaches and other team admins will only be able to view camp registrations that exactly match the teams they manage."
            })}
            value={orgSettings.campSettings?.enableRestrictedCampMode ?? false}
            labelType="inside"
            onChange={newVal => {
              setOrgSettings({
                campSettings: {
                  ...orgSettings.campSettings,
                  enableRestrictedCampMode: !orgSettings.campSettings?.enableRestrictedCampMode
                }
              });
            }}
          />

          <StyledButton
            className="mt-4"
            text={translate.common.Save}
            onClick={async () => {
              try {
                await getBifrost().org__server__updateOrgSettings.fetchServer({
                  id: p.initialOrgSettings.id,
                  orgSettings: {
                    campSettings: {
                      confirmationEmailMessage: orgSettings.campSettings?.confirmationEmailMessage ?? common__magicDeleteValue(),
                      defaultReplyToEmailAddress:
                        orgSettings.campSettings?.defaultReplyToEmailAddress ?? common__magicDeleteValue(),
                      enableRestrictedCampMode: orgSettings.campSettings?.enableRestrictedCampMode ?? common__magicDeleteValue()
                    }
                  }
                });
              } catch (e) {
                openErrorToast(
                  translate({
                    defaultMessage:
                      "There was a problem updating the settings. Please try again or contact support@olliesports.com"
                  })
                );
              }
            }}
          />
        </>
      )}
    </Form>
  );
}
