import { CircularProgress, Typography } from "@material-ui/core";
import { TouchableOpacity, View, Image } from "react-native-web";
import _ from "lodash";
import { translate } from "@ollie-sports/i18n";
import { COLORS, api } from "@ollie-sports/core";
import { useRef, useState } from "react";
import { Form, PrettyCoolTextAreaWithLabel, PrettyCoolTextInput, PrettyCoolTextInputWithLabel } from "../../components/Form";
import { StyledButton } from "../../components/StyledButton";
import { ShadowView } from "../../components/ShadowView";
import { downscaleImage, fileToBase64, getBlob } from "../../utils/fileUtils";
import { AddCircle } from "@material-ui/icons";
import { addHeightAndWidthToURI } from "../../utils/imageUrlHelpers";
import {
  OrgId,
  OrgRegistrationPackageId,
  OrgRegistrationSuccessMessage,
  OrgRegistrationSuccessMessageId
} from "@ollie-sports/models";
import { getBifrost } from "../../services/bifrost.service";
import { useOrgRegistrationPackages } from "../../hooks/useOrgRegistrationPackages";

export function AddEditRegistrationSuccessMessageModal(
  p: (
    | {
        type: "add";
        orgId: OrgId;
      }
    | {
        type: "edit";
        initialSuccessMessage: OrgRegistrationSuccessMessage;
      }
  ) & {
    onComplete: (successMessageId: OrgRegistrationSuccessMessageId) => Promise<void>;
    thisPackageId?: OrgRegistrationPackageId;
  }
) {
  const orgId = p.type === "edit" ? p.initialSuccessMessage.orgId : p.orgId;
  const orgRegistrationPackages =
    useOrgRegistrationPackages({
      orgId
    }) || [];
  const numOrgRegistrationPackagesUsingThisSuccessMessage =
    p.type === "edit"
      ? orgRegistrationPackages?.filter(orp => orp.successMessageId === p.initialSuccessMessage.id && orp.id !== p.thisPackageId)
          .length
      : undefined;
  const [errorMsg, setErrorMsg] = useState("");
  const [title, setTitle] = useState(p.type === "edit" ? p.initialSuccessMessage.title : "");
  const [message, setMessage] = useState(p.type === "edit" ? p.initialSuccessMessage.message : "");
  const [isSaving, setIsSaving] = useState(false);

  return (
    <View style={{ flex: 1 }}>
      {p.type === "edit" && numOrgRegistrationPackagesUsingThisSuccessMessage ? (
        <div className="mb-4 font-bold text-sm leading-none">
          {translate(
            {
              defaultMessage:
                "NOTE: Editing a confirmation message will update it everywhere that it is used. This message is currently used by {num, plural, one {{num} other package} other {{num} other packages}}"
            },
            { num: numOrgRegistrationPackagesUsingThisSuccessMessage }
          )}
        </div>
      ) : null}
      <Form
        children={isFormValid => {
          return (
            <View style={{ flex: 1 }}>
              <PrettyCoolTextInputWithLabel
                isRequired
                value={title}
                onChange={newVal => {
                  setTitle(newVal ?? "");
                }}
                label={translate.common.Title}
                inputProps={{ placeholder: `${translate({ defaultMessage: "E.g. Ollie FC South Success Message" })}...` }}
              />
              <PrettyCoolTextAreaWithLabel
                isRequired
                value={message}
                style={{ marginTop: 16 }}
                onChange={newVal => {
                  setMessage(newVal ?? "");
                }}
                label={translate.common.Message}
                placeholder={`${translate({ defaultMessage: "E.g. Thank you for registering for Ollie FC!" })}...`}
              />

              <StyledButton
                text={translate.common.Done}
                disabled={!isFormValid}
                onClick={async () => {
                  try {
                    if (p.type === "add") {
                      const { data: newSuccessMessageId } =
                        await getBifrost().orgRegistrationSuccessMessage__client__addSuccessMessage.fetchClient({
                          successMessage: {
                            message,
                            orgId,
                            title
                          }
                        });
                      await p.onComplete(newSuccessMessageId);
                    } else {
                      await getBifrost().orgRegistrationSuccessMessage__client__updateSuccessMessage.fetchClient({
                        successMessage: {
                          ...p.initialSuccessMessage,
                          message,
                          title
                        }
                      });
                      await p.onComplete(p.initialSuccessMessage.id);
                    }
                  } catch (e) {
                    setErrorMsg(translate.common.SomethingWentWrong);
                  }
                }}
                className="w-full mt-4"
              />
              {errorMsg ? <Typography style={{ color: COLORS.red, marginTop: 30 }}>{errorMsg}</Typography> : null}
            </View>
          );
        }}
      />
    </View>
  );
}
