import { Org } from "@ollie-sports/models";
import { BifrostSubscription, createBifrostSubscription } from "@ollie-sports/react-bifrost";
import { getUniversalHelpers } from "../../helpers";

export async function org__server__getOrgBySlugNoAuth(p: { slug: string }): Promise<Org | null> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const orgData = (
    await h.Org.query({
      where: [{ slug: ["==", p.slug] }]
    })
  ).docs;

  return orgData.length > 0 ? orgData[0] : null;
  // SERVER_ONLY_TOGGLE
}

org__server__getOrgBySlugNoAuth.auth = async (req: any) => {};

// i18n certified - complete
