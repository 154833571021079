import { Team, OrgId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../helpers";
import { validateToken } from "../internal-utils/server-auth";

export async function org__server__getOrgTeams(p: { orgId: OrgId }): Promise<Team[]> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return (await h.Team.query({ where: [{ orgId: ["==", p.orgId] }, { deletedAtMS: ["==", 0] }] })).docs;
  // SERVER_ONLY_TOGGLE
}

org__server__getOrgTeams.auth = async (req: any) => {
  await validateToken(req);
};

// i18n certified - complete
