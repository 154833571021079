import { getUniversalHelpers } from "../../helpers";
import { Org, OrgId, OrgSecret, OrgSettings } from "@ollie-sports/models";
import { BifrostSubscription, createBifrostSubscription } from "@ollie-sports/react-bifrost";

export function org__client__getOrgSubscription(p: { orgId: OrgId }): BifrostSubscription<Org | null> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const disposeFns: Function[] = [];

  let instance = createBifrostSubscription<Org | null>({
    dispose: () => disposeFns.forEach(fn => fn())
  });

  const sub = h.Org.docSubscription(p.orgId).subscribe(
    async r1 => {
      instance.nextData(r1 ?? null);
    },
    e => {
      console.error(e.message);
    }
  );

  disposeFns.push(sub.unsubscribe);

  return instance;
}

type AllOrgInfo = { org: Org; orgSettings: OrgSettings; orgSecret: OrgSecret | null };

export function org__client__getAllOrgInfoSubscription(p: { orgId: OrgId }): BifrostSubscription<AllOrgInfo | null> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const disposeFns: Function[] = [];

  let instance = createBifrostSubscription<AllOrgInfo | null>({
    dispose: () => disposeFns.forEach(fn => fn())
  });

  const orgInfo: Partial<AllOrgInfo> = {};
  const hasFired = { org: false, orgSettings: false, orgSecret: false };

  const sendNext = () => {
    if (Object.values(hasFired).every(a => a)) {
      if (orgInfo.org) {
        instance.nextData(orgInfo as AllOrgInfo);
      } else {
        instance.nextData(null);
      }
    }
  };

  const sub1 = h.Org.docSubscription(p.orgId).subscribe(
    async o => {
      if (o) {
        orgInfo.org = o;
      }

      hasFired.org = true;

      sendNext();
    },
    e => {
      hasFired.org = true;
      console.error(e.message);
    }
  );

  const sub2 = h.OrgSettings.docSubscription(p.orgId).subscribe(
    async o => {
      if (o) {
        orgInfo.orgSettings = o;
      }

      hasFired.orgSettings = true;

      sendNext();
    },
    e => {
      hasFired.orgSettings = true;
      console.error(e.message);
    }
  );

  const sub3 = h.OrgSecret.docSubscription(p.orgId).subscribe(
    async o => {
      if (o) {
        orgInfo.orgSecret = o;
      }

      hasFired.orgSecret = true;

      sendNext();
    },
    e => {
      hasFired.orgSecret = true;
      console.error(e.message);
    }
  );

  disposeFns.push(sub1.unsubscribe);
  disposeFns.push(sub2.unsubscribe);
  disposeFns.push(sub3.unsubscribe);

  return instance;
}

// i18n certified - complete
