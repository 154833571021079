import { Org } from "@ollie-sports/models";
import { BifrostSubscription, createBifrostSubscription } from "@ollie-sports/react-bifrost";
import { getUniversalHelpers } from "../../helpers";

export async function org__server__updateOrgNumberOfTeamSponsorsAllowed(p: {
  orgId: string;
  numberOfTeamSponsorsAllowed: number;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.Org.update({
    id: p.orgId,
    doc: {
      numTeamSponsorsAllowed: p.numberOfTeamSponsorsAllowed
    }
  });
  // SERVER_ONLY_TOGGLE
}

org__server__updateOrgNumberOfTeamSponsorsAllowed.auth = async (req: any) => {};
// i18n certified - complete
