import { Typography, Box, SvgIcon } from "@material-ui/core";
import { TouchableOpacity, View } from "react-native-web";
import _ from "lodash";
import { Org, OrgRegistrationTeamSummary } from "@ollie-sports/models";
import { CenteredLoader } from "../../components/CenteredLoader";
import { translate } from "@ollie-sports/i18n";
import { useLocation, useParams } from "react-router-dom";
import { useOrg } from "../../hooks/useOrg";
import { ShadowView } from "../../components/ShadowView";
import CoolerTable, { CoolerTableColumnDef, CoolerTableMethods } from "../../components/CoolerTable";
import { TableSectionWrapper } from "./components/TableSectionWrapper";
import { BackButton } from "../../components/BackButton";
import { getBifrost } from "../../services/bifrost.service";
import { usePersistentState } from "../../utils/usePersistentState";
import { CoolSelectInput } from "../../components/Inputs/CoolSelectInput";
import { useOrgSeasons } from "../../hooks/useOrgSeasons";
import { useOrgTeams } from "../../hooks/useOrgTeams";
import { COLORS, formatMoneyCentsToDollarCentPrettyString } from "@ollie-sports/core";
import { useEffect, useRef, useState } from "react";
import { CoolMultiSelectInput } from "../../components/Inputs/CoolMultiSelectInput";
import { CoolTextInput } from "../../components/Inputs/CoolTextInput";
import { useOrgTeamTags } from "../../hooks/useOrgTeamTags";
import getFullScreenModal from "../../components/modals/getFullscreenModal";
import { EyeIcon } from "@heroicons/react/24/outline";
import { StyledAsteriskText } from "../../components/StyledAsteriskTest";
import { ActionButtonDropdown } from "../../components/ActionButtonDropdown";
import { MoreHorizontal } from "react-feather";
import { LoadingIndicator } from "../../utils/openLoadingIndicator";
import { PrettyCheckbox } from "../../components/Form";
import { useOrgCurrentSeasonId } from "../../utils/useOrgCurrentSeasonId";

export default function OrgReportsMoneyOwed() {
  const params: any = useParams();
  const orgId = params.orgId;

  const { org, isLoading } = useOrg({ orgId });

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isLoading ? (
          <CenteredLoader />
        ) : org ? (
          <OrgReportsMoneyOwedInner org={org} />
        ) : (
          <Typography>{translate({ defaultMessage: "Failed to load org" })}</Typography>
        )}
      </View>
    </Box>
  );
}

function OrgReportsMoneyOwedInner(p: { org: Org }) {
  const coolerTableRef = useRef<CoolerTableMethods>(null);
  const { data: reportData, isFetching } = getBifrost().reports__server__getMoneyOwedForOrg.useServer(
    {
      orgId: p.org.id
    },
    { notifyOnMetaDataChanges: true }
  );

  const sortedItems: { name: string; value: number }[] = [
    {
      name: translate({ defaultMessage: "Scheduled Registration Payments" }),
      value: reportData?.scheduledRegistrationPayments ?? 0
    },

    {
      name: translate({ defaultMessage: "Unregistered" }),
      value: reportData?.notYetRegistered ?? 0
    },

    {
      name: translate({ defaultMessage: "Past Due Registration Payment Installments" }),
      value: reportData?.pastDueRegistrationPaymentPlans ?? 0
    },

    {
      name: translate({ defaultMessage: "Outstanding Invoices" }),
      value: reportData?.notYetDueDueManualInvoices ?? 0
    },

    {
      name: translate({ defaultMessage: "Past Due Invoices" }),
      value: reportData?.pastDueManualInvoices ?? 0
    },

    {
      name: translate.common.Total,
      value: reportData?.total ?? 0
    }
  ];
  return (
    <div>
      <div className="absolute inset-0">
        <LoadingIndicator isVisible={isFetching} />
      </div>
      <div className="flex flex-row">
        <h1 className="flex-1 text-2xl sm:text-4xl mt-4 mb-4">{translate({ defaultMessage: "Money Owed" })}</h1>
        <ActionButtonDropdown
          style={{ marginLeft: "auto" }}
          color="secondary"
          variant="contained"
          actions={[
            {
              key: "export",
              label: () => translate.common.Export,
              onClick: async () => {
                coolerTableRef.current?.downloadCurrentDataToCSV("money-owed.csv");
              }
            }
          ]}
        >
          <span style={{ fontWeight: "bold", marginRight: 6 }}>{translate({ defaultMessage: "Actions" })}</span>
          <SvgIcon style={{ paddingRight: 6 }}>
            <MoreHorizontal />
          </SvgIcon>
        </ActionButtonDropdown>
      </div>

      <CoolerTable
        methodsRef={coolerTableRef}
        items={sortedItems}
        columnDefs={[
          {
            label: translate({
              defaultMessage: "Item",
              description: "Used as a header for a table for a column that has some item in it"
            }),
            getValue: item => {
              return item.name;
            }
          },
          {
            label: translate.common.Amount,
            getValue: item => {
              return formatMoneyCentsToDollarCentPrettyString(item.value);
            }
          }
        ]}
        getItemKey={a => a.name}
        getRowCustomClassName={item => {
          if (item.name === translate.common.Total) {
            return "font-black border-t";
          }
          return "";
        }}
      />
    </div>
  );
}
