import { Typography, useTheme, useMediaQuery, Box, Button, SvgIcon } from "@material-ui/core";
import { View } from "react-native-web";
import _ from "lodash";
import { Org } from "@ollie-sports/models";
import { CenteredLoader } from "../../components/CenteredLoader";
import { translate } from "@ollie-sports/i18n";
import { useParams } from "react-router-dom";
import { getBifrost } from "../../services/bifrost.service";
import { useOrg } from "../../hooks/useOrg";
import { useOrgTeamTags } from "../../hooks/useOrgTeamTags";
import CoolerTable from "../../components/CoolerTable";
import { useOrgTeams } from "../../hooks/useOrgTeams";
import getConfirm from "../../components/modals/getConfirm";
import { TrashIcon, PencilIcon, PlusCircleIcon } from "@heroicons/react/24/outline";

import getFullScreenModal from "../../components/modals/getFullscreenModal";
import OrgTeamTagAddEditModal, { openOrgTeamTagAddEditModal } from "./OrgTeamTagAddEdit";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { COLORS } from "@ollie-sports/core";
import { StyledText } from "../../components/StyledText";

export default function OrgTeamTags() {
  const params: any = useParams();
  const orgId = params.orgId;

  const { org, isLoading } = useOrg({ orgId });

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isLoading ? (
          <CenteredLoader />
        ) : org ? (
          <OrgTeamTagsInner org={org} />
        ) : (
          <Typography>{translate({ defaultMessage: "Failed to load org" })}</Typography>
        )}
      </View>
    </Box>
  );
}

function OrgTeamTagsInner(p: { org: Org }) {
  const orgTeamTags = useOrgTeamTags({ orgId: p.org.id });
  const orgTeams = useOrgTeams({ orgId: p.org.id });
  return (
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: "row" }}>
        <h1 className="flex-1 text-2xl sm:text-4xl mt-4">{translate.common.TeamTags}</h1>

        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            openOrgTeamTagAddEditModal({
              org: p.org,
              type: "add"
            });
          }}
        >
          <SvgIcon style={{ paddingRight: 6 }}>
            <PlusCircleIcon />
          </SvgIcon>
          {translate({ defaultMessage: "New Team Tag" })}
        </Button>
      </View>
      {!orgTeamTags ? null : orgTeamTags.length ? (
        <CoolerTable
          style={{ marginTop: 30 }}
          items={orgTeamTags}
          defaultSortSettings={{
            label: translate.common.Tag,
            dir: "asc"
          }}
          columnDefs={[
            {
              label: translate.common.Tag,
              getValue(item) {
                return item.tag;
              },
              sortItems(items, dir) {
                return _.orderBy(items, a => a.tag, dir);
              }
            },
            {
              label: translate({ defaultMessage: "# Teams", description: "Number of teams" }),
              getValue(item) {
                return orgTeams?.filter(t => !!t.assignedOrgTagIds?.[item.id])?.length ?? "0";
              },
              sortItems(items, dir) {
                return _.orderBy(items, a => orgTeams?.filter(t => !!t.assignedOrgTagIds?.[a.id])?.length ?? 0, dir);
              }
            }
          ]}
          rowButtons={[
            {
              getLabel: () => translate.common.Edit,
              getIcon: () => <PencilIcon color={COLORS.blue_66} />,
              type: "inline",
              onClick: async item => {
                openOrgTeamTagAddEditModal({
                  org: p.org,
                  type: "edit",
                  orgTeamTag: item
                });
              }
            },
            {
              getLabel: () => translate.common.Delete,
              type: "inline",
              getIcon: () => <TrashIcon color={COLORS.red_66} />,
              async onClick(item) {
                const confirm = await getConfirm({
                  confirmButtonColor: "red",
                  confirmText: translate.common.Delete,
                  subtitle: translate({
                    defaultMessage:
                      "WARNING PLEASE READ: Are you sure you want to delete this tag? This is a dangerous operation. All teams with the assigned tag will have the tag removed. Additionally, this tag will be removed from all conversations, tryouts, and tryout registrations that are currently assigned this tag. Some club conversations may also disappear if this tag is the only selected filter."
                  }),
                  title: translate({ defaultMessage: "Delete Tag?" })
                });
                if (confirm) {
                  await getBifrost().orgTeamTag__server__deleteTeamTag.fetchServer({
                    orgTeamTag: item,
                    selfAccountId: getCurrentUserAccountId()
                  });
                }
              },
              getTheme(item) {
                return "red";
              }
            }
          ]}
          getItemKey={item => item.id}
        />
      ) : (
        <StyledText style={{ marginTop: 30, fontSize: 18, marginLeft: 16, marginRight: 16, lineHeight: 1.5 }}>
          {translate({
            defaultMessage:
              "Team Tags allow you to categorize your teams. You can create tags to group teams based on any criteria such as region (e.g., North, South, Metro) or play level (e.g., Premier, Select). Team tags enable you to efficiently manage your teams for different purposes, including setting up custom chat channels, organizing tryouts, creating registration packages, or filtering events."
          })}
        </StyledText>
      )}
    </View>
  );
}
