import { TeamId, Team__StaffTypes, AccountId, Account, OrgId, ORG_PERMISSIONS } from "@ollie-sports/models";
import express from "express";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";

type Action = "add" | "remove";

export async function org__server__addRemoveAdmin(
  p:
    | {
        orgId: OrgId;
        accountId: AccountId;
        type: "byAccountId";
        action: Action;
        permissions?: {
          [feature in ORG_PERMISSIONS]?: boolean;
        };
      }
    | {
        orgId: OrgId;
        email: string;
        type: "byEmail";
        action: Action;
        permissions?: {
          [feature in ORG_PERMISSIONS]?: boolean;
        };
      }
) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  let account: Account | null | undefined;
  if (p.type === "byAccountId") {
    account = await h.Account.getDoc(p.accountId);
  } else if (p.type === "byEmail") {
    account = (await h.Account.query({ where: [{ deletedAtMS: ["==", 0] }, { email: ["==", p.email] }] })).docs[0];
  }
  const org = await h.Org.getDoc(p.orgId);

  if (!org || !account) {
    throw new Error("Org or account not defined");
  }

  if (p.action === "add") {
    await h.Org.update({
      id: p.orgId,
      doc: {
        accounts: {
          [account.id]: {
            exists: true,
            permissions: p.permissions ?? { manageOrgAccounts: true, manageOrgChat: true, manageTeams: true },
            title: "Org Admin"
          }
        }
      }
    });
  }

  if (p.action === "remove") {
    await h.Org.update({
      id: p.orgId,
      doc: {
        accounts: {
          [account.id]: h._MagicDeleteValue
        }
      }
    });
  }
  // SERVER_ONLY_TOGGLE
}

org__server__addRemoveAdmin.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};

// i18n certified - complete
