import { Typography } from "@material-ui/core";
import _ from "lodash";
import {
  OrgInvoiceChild,
  OrgInvoiceParent,
  OrgInvoiceTypes,
  OrgInvoice__ManualPaymentPlanInstallment,
  OrgInvoice__RegistrationPaymentPlanInstallment,
  OrgPayment,
  OrgPaymentInvoiceCredit,
  OrgPaymentInvoiceCreditApplicationMethod,
  OrgPaymentType,
  PaymentMethodType
} from "@ollie-sports/models";
import { translate } from "@ollie-sports/i18n";
import { getBifrost } from "../../services/bifrost.service";
import {
  COLORS,
  filterOrgPaymentInvoices,
  formatMoneyCentsToDollarCentPrettyString,
  getIndividualOrgInvoiceAmountDetails
} from "@ollie-sports/core";
import { useState } from "react";
import { Form, PrettyCoolDateInput, PrettyCoolSelectInput, PrettyCoolTextInputWithLabel } from "../../components/Form";
import { openModal } from "../../components/modals/imperativeModal";
import { FullScreenModal } from "../../components/modals/getFullscreenModal";
import { StyledText } from "../../components/StyledText";
import { View } from "react-native-web";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { openErrorToast } from "../../utils/openErrorToast";
import moment from "moment";

type PropsForAddInstallment = {
  orgInvoiceParent: OrgInvoiceParent;
  scheduledChildrenOrgInvoices: OrgInvoiceChild[];
  orgPayments: OrgPayment[];
  onComplete: () => Promise<void>;
  exampleChildInvoice: OrgInvoice__ManualPaymentPlanInstallment | OrgInvoice__RegistrationPaymentPlanInstallment;
};

export function openOrgPaymentInvoiceAddInstallmentModal(p: PropsForAddInstallment) {
  return new Promise<string | undefined>(res => {
    const modal = openModal({
      body: (
        <OrgPaymentInvoiceAddInstallmentModal
          {...p}
          onRequestDismiss={() => {
            modal.close();
          }}
        />
      )
    });
  });
}

function OrgPaymentInvoiceAddInstallmentModal(p: PropsForAddInstallment & { onRequestDismiss: () => void }) {
  const [isLoading, setIsLoading] = useState(false);
  const [amountCents, setAmountCents] = useState<number | undefined>();
  const [amountShadow, setAmountShadow] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [scheduledDateMS, setScheduledDateMS] = useState<number | undefined>();
  const [dueDateMS, setDueDateMS] = useState<number | undefined>();

  return (
    <Form
      children={isFormValid => {
        return (
          <FullScreenModal
            title={translate({ defaultMessage: "Add Installment" })}
            bottomButton={{
              title: translate.common.Add,
              enabled: isFormValid,
              onPress: async () => {
                setIsLoading(true);
                try {
                  if (!amountCents || !scheduledDateMS || !dueDateMS) {
                    throw new Error("");
                  }

                  const newInstallment:
                    | Omit<OrgInvoice__ManualPaymentPlanInstallment, "id" | "createdAtMS">
                    | Omit<OrgInvoice__RegistrationPaymentPlanInstallment, "id" | "createdAtMS"> =
                    p.orgInvoiceParent.type === OrgInvoiceTypes.manual
                      ? {
                          type: OrgInvoiceTypes.manualPaymentPlanInstallment,
                          amountDueCents: amountCents,
                          dueDateMS,
                          autoChargeDateMS: scheduledDateMS,
                          derivedTotalAmountPaidCentsBeforeAllFees: 0,
                          invoiceGroupId: p.orgInvoiceParent.id,
                          accountIdScheduledToPay: p.exampleChildInvoice.accountIdScheduledToPay,
                          lateFeeCentsToBeIssuedIfLate: p.exampleChildInvoice.lateFeeCentsToBeIssuedIfLate,
                          memo: p.exampleChildInvoice.memo,
                          orgId: p.exampleChildInvoice.orgId,
                          orgPaymentPlanId: p.exampleChildInvoice.orgPaymentPlanId,
                          parentOrgInvoiceId: p.orgInvoiceParent.id,
                          playerBundleId: p.exampleChildInvoice.playerBundleId,
                          thisInvoicePaidInFullDateMS: 0
                        }
                      : {
                          type: OrgInvoiceTypes.registrationPaymentPlanInstallment,
                          amountDueCents: amountCents,
                          dueDateMS,
                          autoChargeDateMS: scheduledDateMS,
                          derivedTotalAmountPaidCentsBeforeAllFees: 0,
                          invoiceGroupId: p.orgInvoiceParent.id,
                          accountIdScheduledToPay: p.exampleChildInvoice.accountIdScheduledToPay,
                          lateFeeCentsToBeIssuedIfLate: p.exampleChildInvoice.lateFeeCentsToBeIssuedIfLate,
                          memo: p.exampleChildInvoice.memo,
                          orgId: p.exampleChildInvoice.orgId,
                          orgPaymentPlanId: p.exampleChildInvoice.orgPaymentPlanId,
                          parentOrgInvoiceId: p.orgInvoiceParent.id,
                          playerBundleId: p.exampleChildInvoice.playerBundleId,
                          thisInvoicePaidInFullDateMS: 0,
                          orgRegistrationPackageId: p.orgInvoiceParent.orgRegistrationPackageId,
                          orgSeasonId: p.orgInvoiceParent.orgSeasonId
                        };

                  const { data: res } = await getBifrost().orgPayment__server__addInstallment.fetchServer({
                    newInstallment,
                    orgId: p.orgInvoiceParent.orgId,
                    parentOrgInvoiceId: p.orgInvoiceParent.id
                  });

                  if (res.status === "error") {
                    setErrorMsg(translate.common.SomethingWentWrong);
                    return;
                  }
                  await p.onComplete();
                  p.onRequestDismiss();
                } catch (e) {
                  openErrorToast(
                    translate({
                      defaultMessage:
                        "There was a problem adding the installment. Please try again or contact support@olliesports.com"
                    })
                  );
                }
                setIsLoading(false);
              }
            }}
            onRequestDismiss={async () => {
              await p.onRequestDismiss();
            }}
          >
            <div>
              <PrettyCoolTextInputWithLabel
                label={translate({ defaultMessage: "Amount" })}
                isRequired
                isMoney
                onChange={newAmtRaw => {
                  const newAmt = newAmtRaw?.match(/\d+\.?\d?\d?/)?.[0];
                  setAmountShadow(newAmt || "");
                  setAmountCents(newAmt ? Number(newAmt) * 100 : undefined);
                }}
                validate={val => {
                  if (!val) {
                    return translate.common.IsRequired;
                  }
                  return "";
                }}
                inputProps={{ type: "number", min: 0 }}
                value={amountShadow}
                style={{ marginTop: 16 }}
              />
              <div className="mt-4">
                <PrettyCoolDateInput
                  value={scheduledDateMS ? moment(scheduledDateMS).toDate() : undefined}
                  isRequired
                  className="flex-1"
                  onChange={newVal => {
                    if (newVal) {
                      setScheduledDateMS(moment(newVal).startOf("day").add(10, "hour").valueOf());
                    } else {
                      setScheduledDateMS(undefined);
                    }
                  }}
                  validate={val => {
                    if (!val) {
                      return translate.common.IsRequired;
                    }
                    if (val && moment(val).valueOf() < Date.now()) {
                      return translate({ defaultMessage: "Charge date must in the future" });
                    }
                    return "";
                  }}
                  placeholderText={translate.common.SelectDotDotDot}
                  label={translate({ defaultMessage: "Charge Date" })}
                />
              </div>
              <div className="mt-4">
                <PrettyCoolDateInput
                  value={dueDateMS ? moment(dueDateMS).toDate() : undefined}
                  isRequired
                  className="flex-1"
                  onChange={newVal => {
                    if (newVal) {
                      setDueDateMS(moment(newVal).endOf("day").valueOf());
                    } else {
                      setDueDateMS(undefined);
                    }
                  }}
                  validate={val => {
                    if (!val) {
                      return translate.common.IsRequired;
                    }
                    if (val && scheduledDateMS && moment(val).valueOf() < scheduledDateMS) {
                      return translate({ defaultMessage: "Due date must be after the charge date" });
                    }
                    if (val && moment(val).valueOf() < Date.now()) {
                      return translate({ defaultMessage: "Due date must in the future" });
                    }
                    return "";
                  }}
                  placeholderText={translate.common.SelectDotDotDot}
                  label={translate.common.DueDate}
                />
              </div>
              {errorMsg ? <Typography style={{ color: COLORS.red, marginTop: 30 }}>{errorMsg}</Typography> : null}
            </div>
          </FullScreenModal>
        );
      }}
    />
  );
}
