import { Typography, Box } from "@material-ui/core";
import { View } from "react-native-web";
import { OrgInvoiceTypes, OrgInvoice__Manual } from "@ollie-sports/models";
import { getCurrentLocale, translate } from "@ollie-sports/i18n";
import { useLocation, useParams } from "react-router-dom";
import _ from "lodash";
import { getAuthToken, getBifrost } from "../../services/bifrost.service";
import { useOrg } from "../../hooks/useOrg";
import { CenteredLoader } from "../../components/CenteredLoader";
import { useOrgSettings } from "../../hooks/useOrgSettings";
import { OrgInvoicePay } from "./OrgInvoicePay";
import { OrgUnathenticatedPageHeader } from "../../components/OrgUnauthenticatedPageHeader";
import { getCurrentUserAccountId, useCurrentUserAccount, useCurrentUserAccountWithSkip } from "../../hooks/commonDataUtils";
import { useState, useEffect } from "react";
import { getFbApp } from "../../services/fb.service";
import { usePromise } from "../../utils/hooks/usePromise";
import { ShadowView } from "../../components/ShadowView";
import { StyledText } from "../../components/StyledText";
import { useGlobalState } from "../../services/global-state.service";
import { openErrorToast } from "../../utils/openErrorToast";
import { getOlliePipe } from "../../services/olliePipe.service";

export default function OrgInvoiceManual() {
  const params: any = useParams();
  const orgInvoiceId = params.orgInvoiceId;
  const orgId = params.orgId;
  const queryParams = new URLSearchParams(useLocation().search);
  const serverJwtToken = queryParams.get("serverJwtToken");
  const [errorLoggingIn, setErrorLoggingIn] = useState(false);
  const currentUser = useCurrentUserAccountWithSkip(false).data;
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (serverJwtToken) {
      getFbApp()
        .auth()
        .signInWithCustomToken(serverJwtToken)
        .then(
          () => {
            setErrorLoggingIn(false);
            document.dispatchEvent(new CustomEvent("notify-ollie-app-of-ready-if-inside-iframe"));
          },
          err => {
            console.error(err);
            setErrorLoggingIn(true);
          }
        );
    }
  }, [serverJwtToken]);

  const {
    data: orgInvoice,
    isLoading: isInvoiceLoading,
    refetch: refetchInvoice
  } = getBifrost().orgInvoice__server__getOrgInvoiceNoAuth.useServer(
    {
      orgInvoiceId: orgInvoiceId
    },
    {
      notifyOnMetaDataChanges: true
    }
  );

  const { data: org, isLoading: isOrgLoading } = getBifrost().org__server__getOrgNoAuth.useServer(
    { orgId },
    { notifyOnMetaDataChanges: true }
  );

  const { data: orgSettings, isLoading: isOrgSettingsLoading } = getBifrost().orgSettings__server__getOrgSettingsNoAuth.useServer(
    { orgId },
    { notifyOnMetaDataChanges: true }
  );

  const { data: playerBundle, isLoading: isPlayerBundleLoading } =
    getBifrost().playerBundle__server__getPlayerBundleNoAuth.useServer(
      {
        id: orgInvoice?.playerBundleId ?? ""
      },
      { enabled: !!orgInvoice, notifyOnMetaDataChanges: true }
    );

  const { data: orgPaymentInvoiceCredits, isLoading: isOrgPaymentInvoiceCreditsLoading } =
    getBifrost().orgInvoice__server__getOrgPaymentInvoiceCreditsForOrgInvoiceNoAuth.useServer(
      {
        orgId,
        orgInvoiceId: orgInvoice?.id ?? ""
      },
      { notifyOnMetaDataChanges: true, enabled: !!orgInvoice }
    );

  if (errorLoggingIn) {
    return (
      <div className="flex m-4">
        {translate({ defaultMessage: "Unable to load the page. Please try again or contact support@olliesports.com" })}
      </div>
    );
  }

  return (
    <Box display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isOrgLoading ||
        isInvoiceLoading ||
        isPlayerBundleLoading ||
        isOrgSettingsLoading ||
        isOrgPaymentInvoiceCreditsLoading ? (
          <CenteredLoader />
        ) : org &&
          orgInvoice &&
          playerBundle &&
          orgSettings &&
          orgInvoice.type === OrgInvoiceTypes.manual &&
          orgPaymentInvoiceCredits ? (
          <OrgUnathenticatedPageHeader org={org} orgSettings={orgSettings}>
            {orgInvoice.thisInvoicePaidInFullDateMS ? (
              <ShadowView style={{ marginTop: 30, padding: 30, borderRadius: 20 }}>
                <StyledText>
                  {translate(
                    {
                      defaultMessage:
                        "Thank you for your payment! You can view your payment history on the Tools tab in the Ollie app."
                    },
                    { email: currentUser?.email || "" }
                  )}
                </StyledText>
              </ShadowView>
            ) : (
              <OrgInvoicePay
                type="manual"
                org={org}
                orgSettings={orgSettings}
                orgInvoice={orgInvoice}
                playerBundle={playerBundle}
                error={{
                  msg: errorMsg,
                  onClear: () => {
                    setErrorMsg("");
                  }
                }}
                onComplete={async data => {
                  if (currentUser) {
                    const { data: result } =
                      await getBifrost().orgInvoice__server__payOrgInvoiceAndPotentiallyGeneratePaymentPlanInstallmentInvoices.fetchServer(
                        {
                          orgInvoice: orgInvoice,
                          selfAccountId: getCurrentUserAccountId(),
                          locale: getCurrentLocale(),
                          hasOutstandingAdditionalSteps: false,
                          ...data
                        }
                      );
                    if (result.type === "error") {
                      setErrorMsg(
                        result.prettyErrorReason ??
                          translate({
                            defaultMessage:
                              "There was a problem paying the invoice. Please try again or contact support@olliesports.com"
                          })
                      );
                      openErrorToast(translate({ defaultMessage: "Error! Please view the error message below for details." }));
                      return;
                    }
                    if (result.type === "failed") {
                      setErrorMsg(
                        result.prettyFailureReason ??
                          translate({
                            defaultMessage:
                              "There was a problem paying the invoice. Please try again or contact support@olliesports.com"
                          })
                      );
                      openErrorToast(translate({ defaultMessage: "Error! Please view the error message below for details." }));
                      return;
                    }
                    await refetchInvoice();
                  }
                }}
                orgPaymentInvoiceCredits={orgPaymentInvoiceCredits}
                isEverythingElseValid={true}
              />
            )}
          </OrgUnathenticatedPageHeader>
        ) : (
          <Typography>{translate({ defaultMessage: "Failed to load the invoice" })}</Typography>
        )}
      </View>
    </Box>
  );
}
