import { CircularProgress, Typography } from "@material-ui/core";
import { TouchableOpacity, View, Image } from "react-native-web";
import _ from "lodash";
import { translate } from "@ollie-sports/i18n";
import { COLORS, api } from "@ollie-sports/core";
import { useRef, useState } from "react";
import { Form, PrettyCoolTextInput, PrettyCoolTextInputWithLabel } from "../../components/Form";
import { StyledButton } from "../../components/StyledButton";
import { ShadowView } from "../../components/ShadowView";
import { downscaleImage, fileToBase64, getBlob } from "../../utils/fileUtils";
import { AddCircle } from "@material-ui/icons";
import { addHeightAndWidthToURI } from "../../utils/imageUrlHelpers";

export function AddReferenceImageModal(p: { onComplete: (data: { title: string; url: string }) => Promise<void> }) {
  const [errorMsg, setErrorMsg] = useState("");
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const INPUT_ID = useRef(Math.random() + "").current;

  return (
    <View style={{ flex: 1 }}>
      <Form
        children={isFormValid => {
          return (
            <View style={{ flex: 1 }}>
              <PrettyCoolTextInputWithLabel
                isRequired
                value={title}
                onChange={newVal => {
                  setTitle(newVal ?? "");
                }}
                label={translate.common.Title}
                inputProps={{ placeholder: `${translate({ defaultMessage: "E.g. Ollie FC South - Fee Breakdown" })}...` }}
              />
              <View style={{ marginTop: 16 }}>
                <label className="text-sm font-extrabold">{translate.common.Image}</label>
                <ShadowView
                  style={{
                    display: "flex",
                    marginTop: 8,
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <input
                    id={INPUT_ID}
                    accept={"image/*"}
                    multiple={false}
                    onChange={async event => {
                      var file = event.target.files?.[0];
                      if (file) {
                        setIsUploading(true);
                        const base64 = await fileToBase64(file);
                        const { blob, height, width } = await getBlob(base64);
                        if (blob && file) {
                          try {
                            const snapshot = await api.orgRegistrationPackage__client__uploadImage({
                              name: file.name.replace(/^.*[\\\/]/, ""),
                              file: blob
                            });
                            const firebaseUrl = await snapshot.ref.getDownloadURL();

                            setUrl(
                              addHeightAndWidthToURI({
                                uri: firebaseUrl,
                                height,
                                width
                              })
                            );
                          } catch (e) {
                            console.log("------------");
                            console.log(e);
                            setErrorMsg(
                              translate({
                                defaultMessage:
                                  "There was a problem uploading the image. Please try again or contact support@olliesports.com"
                              })
                            );
                          }
                        }
                        setIsUploading(false);
                      }
                    }}
                    type="file"
                    style={{ display: "none" }}
                  />
                  <TouchableOpacity
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 15,
                      width: "100%",
                      height: "100%"
                    }}
                    onPress={() => {
                      document.getElementById(INPUT_ID)!.click();
                    }}
                  >
                    {url ? (
                      <img src={url} className="w-auto h-auto max-w-96 max-h-96" alt="" />
                    ) : isUploading ? (
                      <CircularProgress />
                    ) : (
                      <AddCircle
                        style={{
                          backgroundColor: COLORS.white,
                          color: COLORS.grey_07,
                          height: 40,
                          width: 40
                        }}
                      />
                    )}
                  </TouchableOpacity>
                </ShadowView>
              </View>
              <StyledButton
                text={translate.common.Done}
                disabled={!isFormValid}
                onClick={async () => {
                  p.onComplete({
                    title,
                    url
                  });
                }}
                className="w-full mt-4"
              />
              {errorMsg ? <Typography style={{ color: COLORS.red, marginTop: 30 }}>{errorMsg}</Typography> : null}
            </View>
          );
        }}
      />
    </View>
  );
}

function getImageDimensions(url: string): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    let img = new window.Image();
    img.onload = function () {
      //@ts-ignore
      resolve({ width: this.width, height: this.height });
    };
    img.onerror = function (err: any) {
      reject(err);
    };
    img.src = url;
  });
}
