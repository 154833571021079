import { Typography, Box, Button, SvgIcon } from "@material-ui/core";
import { View } from "react-native-web";
import _ from "lodash";
import {
  DownPaymentType,
  ORG_PERMISSIONS,
  Org,
  OrgPaymentPlanInitialPaymentType,
  OrgPaymentPlanType
} from "@ollie-sports/models";
import { CenteredLoader } from "../../components/CenteredLoader";
import { translate } from "@ollie-sports/i18n";
import { useParams } from "react-router-dom";
import { getBifrost } from "../../services/bifrost.service";
import { useOrgPaymentPlans } from "../../hooks/useOrgPaymentPlans";

import CoolerTable, { CoolerTableMethods } from "../../components/CoolerTable";
import { COLORS, compute, formatMoneyCentsToDollarCentPrettyString } from "@ollie-sports/core";
import { openOrgPaymentPlanAddEditModal } from "./OrgPaymentPlanAddEdit";
import { CoolTextInput } from "../../components/Inputs/CoolTextInput";
import { useRef, useState } from "react";
import { CoolSelectInput } from "../../components/Inputs/CoolSelectInput";
import { TrashIcon, PencilIcon, PlusCircleIcon } from "@heroicons/react/24/outline";

import getConfirm from "../../components/modals/getConfirm";
import moment from "moment";
import { openErrorToast } from "../../utils/openErrorToast";
import { PrettyCoolTextInput } from "../../components/Form";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { ActionButtonDropdown } from "../../components/ActionButtonDropdown";
import { MoreHorizontal } from "react-feather";

export default function OrgPaymentPlans() {
  const params: any = useParams();
  const orgId = params.orgId;

  const { data: org, isLoading } = getBifrost().org__client__getOrgSubscription.useClientSubscription(
    {
      orgId
    },
    {
      notifyOnMetaDataChanges: true
    }
  );

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isLoading ? (
          <CenteredLoader />
        ) : org ? (
          <OrgPaymentPlansInner org={org} />
        ) : (
          <Typography>{translate({ defaultMessage: "Failed to load org" })}</Typography>
        )}
      </View>
    </Box>
  );
}

function OrgPaymentPlansInner(p: { org: Org }) {
  const orgPaymentPlans = useOrgPaymentPlans({ orgId: p.org.id });
  const [searchInput, setSearchInput] = useState("");
  const [selectedOrgPaymentPlanType, setSelectedOrgPaymentPlanType] = useState<OrgPaymentPlanType | undefined>();
  const hasOrgManageFinancesPermission = compute.org.hasOrgPermission({
    org: p.org,
    accountId: getCurrentUserAccountId(),
    permission: ORG_PERMISSIONS.manageFinances
  });
  const coolTableRef = useRef<CoolerTableMethods>(null);

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: "row" }}>
        <h1 className="flex-1 text-2xl sm:text-4xl mt-4">{translate.common.PaymentPlans}</h1>
        {compute.org.hasOrgPermission({
          accountId: getCurrentUserAccountId(),
          org: p.org,
          permission: ORG_PERMISSIONS.manageFinances
        }) ? (
          <ActionButtonDropdown
            style={{ marginLeft: "auto" }}
            actions={[
              {
                key: "new-payment-plan",
                label: () => translate({ defaultMessage: "New Payment Plan" }),
                onClick: async () => {
                  await openOrgPaymentPlanAddEditModal({
                    org: p.org,
                    type: "add"
                  });
                }
              },
              {
                key: "export",
                label: () => translate({ defaultMessage: "Export CSV" }),
                onClick: () => {
                  coolTableRef.current?.downloadCurrentDataToCSV("payment-plans.csv");
                }
              }
            ]}
          >
            <span style={{ fontWeight: "normal", marginRight: 6 }}>{translate({ defaultMessage: "Actions" })}</span>
            <SvgIcon style={{ paddingRight: 6 }}>
              <MoreHorizontal />
            </SvgIcon>
          </ActionButtonDropdown>
        ) : null}
      </View>
      <div className="pb-8">
        <CoolerTable
          methodsRef={coolTableRef}
          style={{ marginTop: 30 }}
          items={orgPaymentPlans ?? []}
          defaultSortSettings={{
            label: translate.common.Name,
            dir: "asc"
          }}
          noItemsMessage={translate({ defaultMessage: "No payment plans created yet..." })}
          noFilteredItemsMessage={translate({ defaultMessage: "No payment plans matching selected filters..." })}
          columnDefs={[
            {
              label: translate.common.Name,
              getValue(item) {
                return item.name;
              },
              sortItems(items, dir) {
                return _.orderBy(items, a => a.name, dir);
              },
              editable: hasOrgManageFinancesPermission
                ? {
                    onComplete: newItem =>
                      getBifrost().orgPaymentPlan__client__updateOrgPaymentPlan.fetchClient({
                        orgPaymentPlan: newItem
                      }),
                    onError: e => {
                      openErrorToast(translate({ defaultMessage: "Unable to save payment plan name change!" }));
                      console.error(e);
                    },
                    render: a => (
                      <PrettyCoolTextInput
                        onChange={newVal => {
                          a.setItem({ name: newVal });
                        }}
                        inputProps={{ type: "text" }}
                        value={a.item.name}
                      />
                    )
                  }
                : undefined
            },
            {
              label: translate.common.NumPayments,
              getValue(item) {
                if (item.type === OrgPaymentPlanType.monthly) {
                  return item.numberPeriods;
                }
                return item.chargeDatesWithoutYear.length;
              },
              sortItems(items, dir) {
                return _.orderBy(
                  items,
                  a => (a.type === OrgPaymentPlanType.monthly ? a.numberPeriods : a.chargeDatesWithoutYear.length),
                  dir
                );
              }
            },
            {
              label: translate({ defaultMessage: "Start/End Dates" }),
              getValue(item) {
                if (item.type === OrgPaymentPlanType.monthly) {
                  return translate.common["N/A"];
                }
                const endDate = item.chargeDatesWithoutYear?.slice(-1)[0]?.date;
                const start = item.chargeDatesWithoutYear?.[0]
                  ? moment(item.chargeDatesWithoutYear[0].date, "MM-DD").format("MMM D")
                  : "";
                const end = endDate ? moment(endDate, "MM-DD").format("MMM D") : "";

                return _([start, end]).compact().uniq().join(" - ");
              },
              sortItems(items, dir) {
                return _.orderBy(
                  items,
                  a =>
                    a.type === OrgPaymentPlanType.monthly
                      ? ""
                      : a.chargeDatesWithoutYear?.[0]
                      ? moment(a.chargeDatesWithoutYear[0].date, "MM-DD").format("MMM D")
                      : "",
                  dir
                );
              }
            },
            {
              label: translate.common.Type,
              getValue(item) {
                return item.type === OrgPaymentPlanType.evenlySplit
                  ? translate.common.PaymentPlanEvenlySplit
                  : item.type === OrgPaymentPlanType.customPercent
                  ? translate.common.PaymentPlanCustomPercent
                  : translate.common.Monthly;
              },
              sortItems(items, dir) {
                return _.orderBy(items, a => a.type, dir);
              }
            },
            {
              label: translate.common.DownPayment,
              getValue(item) {
                return item.initialPaymentSettings?.type === OrgPaymentPlanInitialPaymentType.fixedAmount
                  ? formatMoneyCentsToDollarCentPrettyString(item.initialPaymentSettings.ammountCents)
                  : item.initialPaymentSettings?.type === OrgPaymentPlanInitialPaymentType.percent
                  ? (item.initialPaymentSettings.percent * 100).toFixed(2) + "%"
                  : item.initialPaymentSettings?.type === OrgPaymentPlanInitialPaymentType.firstInstallment
                  ? translate({ defaultMessage: "First Installment" })
                  : "";
              }
            },
            {
              label: translate({ defaultMessage: "Grace Period Days" }),
              getValue(item) {
                return item.numberOfDaysUntilLate;
              },
              sortItems(items, dir) {
                return _.orderBy(
                  items,
                  a => {
                    return dir === "asc"
                      ? a.numberOfDaysUntilLate ?? Number.MAX_VALUE
                      : a.numberOfDaysUntilLate ?? Number.MIN_VALUE;
                  },
                  dir
                );
              },
              editable: hasOrgManageFinancesPermission
                ? {
                    onComplete: newItem =>
                      getBifrost().orgPaymentPlan__client__updateOrgPaymentPlan.fetchClient({
                        orgPaymentPlan: newItem
                      }),
                    onError: e => {
                      openErrorToast(translate({ defaultMessage: "Unable to save payment grace period change" }));
                      console.error(e);
                    },
                    render: a => (
                      <PrettyCoolTextInput
                        onChange={newVal => {
                          a.setItem({ numberOfDaysUntilLate: newVal ? parseInt(newVal) : undefined });
                        }}
                        inputProps={{ type: "number" }}
                        value={a.item.numberOfDaysUntilLate?.toString() || ""}
                      />
                    )
                  }
                : undefined
            },
            {
              label: translate.common.LateFee,
              getValue(item) {
                return item.lateFeeAmountCents ? formatMoneyCentsToDollarCentPrettyString(item.lateFeeAmountCents) : "";
              },
              sortItems(items, dir) {
                return _.orderBy(
                  items,
                  a => {
                    return dir === "asc" ? a.lateFeeAmountCents ?? Number.MAX_VALUE : a.lateFeeAmountCents ?? Number.MIN_VALUE;
                  },
                  dir
                );
              },
              editable: hasOrgManageFinancesPermission
                ? {
                    onComplete: newItem =>
                      getBifrost().orgPaymentPlan__client__updateOrgPaymentPlan.fetchClient({
                        orgPaymentPlan: newItem
                      }),
                    onError: e => {
                      openErrorToast(translate({ defaultMessage: "Unable to save payment late fee change" }));
                      console.error(e);
                    },
                    getInitialTempStateOnEditStart: a => (a.lateFeeAmountCents || 0) / 100,
                    render: a => (
                      <PrettyCoolTextInput
                        onChange={newVal => {
                          a.setItem({ lateFeeAmountCents: newVal ? Math.floor(parseFloat(newVal) * 100) : undefined });
                          a.setTempState(newVal || undefined);
                        }}
                        inputProps={{ type: "number" }}
                        value={a.tempState}
                      />
                    )
                  }
                : undefined
            }
          ]}
          getItemKey={item => item.id}
          rowButtons={
            compute.org.hasOrgPermission({
              accountId: getCurrentUserAccountId(),
              org: p.org,
              permission: ORG_PERMISSIONS.manageFinances
            })
              ? [
                  {
                    getLabel: () => translate.common.Edit,
                    type: "inline",
                    getIcon: () => <PencilIcon color={COLORS.blue_66} />,
                    onClick: async item => {
                      await openOrgPaymentPlanAddEditModal({
                        org: p.org,
                        type: "edit",
                        initialOrgPaymentPlan: item
                      });
                    }
                  },
                  {
                    getLabel: () => translate.common.Delete,
                    getIcon: () => <TrashIcon color={COLORS.red_66} />,
                    type: "inline",
                    async onClick(item) {
                      const confirm = await getConfirm({
                        confirmButtonColor: "red",
                        subtitle: translate({ defaultMessage: "Are you sure you want to delete this payment plan?" }),
                        title: translate({ defaultMessage: "Delete Payment Plan?" })
                      });
                      if (confirm) {
                        try {
                          await getBifrost().orgPaymentPlan__client__deleteOrgPaymentPlan.fetchClient({
                            orgPaymentPlanId: item.id
                          });
                        } catch (e) {
                          openErrorToast(translate({ defaultMessage: "Problem deleting payment plan!" }));
                        }
                      }
                    },
                    getTheme(item) {
                      return "red";
                    }
                  }
                ]
              : []
          }
          filters={[
            {
              filterComponent: (
                <CoolTextInput
                  value={searchInput}
                  onChange={newVal => {
                    setSearchInput(newVal ?? "");
                  }}
                  showClearButton
                  inputProps={{
                    placeholder: translate({
                      defaultMessage: "Search by name...",
                      description: "Telling the user to search a list by the code"
                    })
                  }}
                />
              ),
              onFilter: items => {
                return searchInput
                  ? items.filter(item =>
                      item.name.toLowerCase().replaceAll(" ", "").includes(searchInput.toLowerCase().replaceAll(" ", ""))
                    )
                  : items;
              }
            },
            {
              filterComponent: (
                <CoolSelectInput
                  options={[
                    {
                      label: translate.common.PaymentPlanEvenlySplit,
                      value: OrgPaymentPlanType.evenlySplit
                    },
                    {
                      label: translate.common.PaymentPlanCustomPercent,
                      value: OrgPaymentPlanType.customPercent
                    }
                  ]}
                  value={selectedOrgPaymentPlanType ?? ""}
                  allowClear
                  placeholder={translate.common.Type}
                  onChange={newVal => {
                    setSelectedOrgPaymentPlanType(newVal === "" ? undefined : (newVal as OrgPaymentPlanType));
                  }}
                />
              ),
              onFilter: items => {
                return selectedOrgPaymentPlanType ? items.filter(item => item.type === selectedOrgPaymentPlanType) : items;
              }
            }
          ]}
        />
      </div>
    </View>
  );
}
