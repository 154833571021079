import {
  Org,
  OrgCoupon,
  OrgCouponType,
  OrgCouponUseType,
  OrgInvoiceTypes,
  OrgInvoice__Manual,
  OrgInvoice__Registration,
  OrgPaymentPlan,
  OrgPaymentPlanType,
  OrgRegistrationPackage,
  OrgSeason,
  OrgSettings,
  PaymentMethodType,
  PlayerBundle
} from "@ollie-sports/models";
import { useEffect, useState } from "react";
import { openModal } from "../../components/modals/imperativeModal";
import { View } from "react-native-web";
import { OrgInvoicePay } from "./OrgInvoicePay";
import moment from "moment";
import { FullScreenModal } from "../../components/modals/getFullscreenModal";
import { translate } from "@ollie-sports/i18n";
import { useOrgSeasons } from "../../hooks/useOrgSeasons";
import { useOrgRegistrationPackages } from "../../hooks/useOrgRegistrationPackages";
import { useOrgPaymentPlans } from "../../hooks/useOrgPaymentPlans";
import _ from "lodash";
import { CoolDateInput } from "../../components/Inputs/CoolDateInput";
import { StyledText } from "../../components/StyledText";
import {
  Form,
  PrettyCoolDateInput,
  PrettyCoolMultiSelectWithLabel,
  PrettyCoolSelectInput,
  PrettyCoolTextInputWithLabel
} from "../../components/Form";
import { TextButton } from "../../components/TextButton";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { CoolSelectInput } from "../../components/Inputs/CoolSelectInput";

export type PartialOrgRegistrationPackage = Pick<
  OrgRegistrationPackage,
  | "referenceImageUrl"
  | "referenceImageTitle"
  | "details"
  | "supportedPaymentPlanIds"
  | "orgSeasonId"
  | "name"
  | "id"
  | "amountCents"
  | "paymentInFullLateFeeAmountCents"
>;

type OrgInvoicePayPreviewSetupProps = {
  org: Org;
  orgSettings: OrgSettings;
  initialOrgInvoicePayPreviewSetupProps?: Partial<OrgInvoicePreviewInnerProps>;
  initialType?: OrgInvoiceTypes.manual | OrgInvoiceTypes.registration;
  allowedTypes: OrgInvoiceTypes.manual | OrgInvoiceTypes.registration | "both";
  previewOrgPaymentPlan?: OrgPaymentPlan;
  onSave: (val?: OrgInvoicePreviewInnerProps) => void;
};

export type OrgInvoicePreviewInnerProps = {
  org: Org;
  orgSettings: OrgSettings;
  previewOverrideOrgCoupon?: OrgCoupon;
  previewOverrideOrgPaymentPlan?: OrgPaymentPlan;
} & (
  | { type: OrgInvoiceTypes.manual; testManualOrgInvoice: OrgInvoice__Manual }
  | {
      type: OrgInvoiceTypes.registration;
      testRegistrationOrgInvoice: OrgInvoice__Registration;
      orgSeason: OrgSeason;
      previewOverrideRegistrationPackage: PartialOrgRegistrationPackage;
    }
);

export function openOrgInvoicePayPreviewSetup(p: OrgInvoicePayPreviewSetupProps) {
  return new Promise<void>(res => {
    const modal = openModal({
      body: (
        <OrgInvoicePayPreviewSetup
          {...p}
          onRequestDismiss={() => {
            modal.close();
            res();
          }}
        />
      )
    });
  });
}

function getInitialType(p: OrgInvoicePayPreviewSetupProps) {
  if (
    p.initialOrgInvoicePayPreviewSetupProps?.type &&
    (p.allowedTypes === p.initialOrgInvoicePayPreviewSetupProps.type || p.allowedTypes === "both")
  ) {
    return p.initialOrgInvoicePayPreviewSetupProps.type;
  }
  if (p.initialType && (p.allowedTypes === p.initialType || p.allowedTypes === "both")) {
    return p.initialType;
  }
  if (p.allowedTypes === OrgInvoiceTypes.manual || p.allowedTypes === "both") {
    return OrgInvoiceTypes.manual;
  }
  return OrgInvoiceTypes.registration;
}

function OrgInvoicePayPreviewSetup(p: OrgInvoicePayPreviewSetupProps & { onRequestDismiss: () => void }) {
  const orgSeasons = useOrgSeasons({ orgId: p.org.id });
  const orgRegistrationPackages = useOrgRegistrationPackages({ orgId: p.org.id });

  const orgPaymentPlansFetched = useOrgPaymentPlans({ orgId: p.org.id });
  const orgPaymentPlans = p.previewOrgPaymentPlan ? [p.previewOrgPaymentPlan] : orgPaymentPlansFetched;
  const [selectedOrgInvoiceType, setSelectedOrgInvoiceType] = useState<OrgInvoiceTypes.manual | OrgInvoiceTypes.registration>(
    getInitialType(p)
  );
  const [selectedOrgSeasonId, setSelectedOrgSeasonId] = useState(
    p.initialOrgInvoicePayPreviewSetupProps?.type === OrgInvoiceTypes.registration
      ? p.initialOrgInvoicePayPreviewSetupProps.orgSeason?.id ?? ""
      : ""
  );
  const [selectedOrgRegistrationPackageId, setSelectedOrgRegistrationPackageId] = useState(
    p.initialOrgInvoicePayPreviewSetupProps?.type === OrgInvoiceTypes.registration
      ? p.initialOrgInvoicePayPreviewSetupProps.previewOverrideRegistrationPackage?.id ?? ""
      : ""
  );
  const selectedOrgRegistrationPackage = orgRegistrationPackages?.find(orp => orp.id === selectedOrgRegistrationPackageId);
  const selectedOrgSeason = orgSeasons?.find(os => os.id === selectedOrgSeasonId);
  const [dueDateMS, setDueDateMS] = useState(
    p.initialOrgInvoicePayPreviewSetupProps?.type === OrgInvoiceTypes.manual
      ? p.initialOrgInvoicePayPreviewSetupProps.testManualOrgInvoice?.dueDateMS ?? moment().add(1, "month").valueOf()
      : p.initialOrgInvoicePayPreviewSetupProps?.type === OrgInvoiceTypes.registration
      ? p.initialOrgInvoicePayPreviewSetupProps.orgSeason?.registrationDueDateMS ?? moment().add(1, "month").valueOf()
      : moment().add(1, "month").valueOf()
  );
  useEffect(() => {
    if (selectedOrgSeason) {
      setDueDateMS(selectedOrgSeason.registrationDueDateMS);
    }
  }, [selectedOrgSeason]);
  useEffect(() => {
    setLateFeeAmount(selectedOrgRegistrationPackage?.paymentInFullLateFeeAmountCents ?? undefined);
    setLateFeeAmountShadow(
      selectedOrgRegistrationPackage?.paymentInFullLateFeeAmountCents
        ? String(selectedOrgRegistrationPackage.paymentInFullLateFeeAmountCents / 100)
        : ""
    );
  }, [selectedOrgRegistrationPackage]);
  const [amountShadow, setAmountShadow] = useState(
    p.initialOrgInvoicePayPreviewSetupProps?.type === OrgInvoiceTypes.manual &&
      p.initialOrgInvoicePayPreviewSetupProps.testManualOrgInvoice?.amountDueCents
      ? String(p.initialOrgInvoicePayPreviewSetupProps.testManualOrgInvoice?.amountDueCents / 100)
      : p.initialOrgInvoicePayPreviewSetupProps?.type === OrgInvoiceTypes.registration &&
        p.initialOrgInvoicePayPreviewSetupProps.previewOverrideRegistrationPackage?.amountCents
      ? String(p.initialOrgInvoicePayPreviewSetupProps.previewOverrideRegistrationPackage.amountCents / 100)
      : ""
  );
  const [amount, setAmount] = useState<number | undefined>(
    p.initialOrgInvoicePayPreviewSetupProps?.type === OrgInvoiceTypes.manual &&
      p.initialOrgInvoicePayPreviewSetupProps.testManualOrgInvoice?.amountDueCents
      ? p.initialOrgInvoicePayPreviewSetupProps.testManualOrgInvoice?.amountDueCents
      : p.initialOrgInvoicePayPreviewSetupProps?.type === OrgInvoiceTypes.registration &&
        p.initialOrgInvoicePayPreviewSetupProps.previewOverrideRegistrationPackage?.amountCents
      ? p.initialOrgInvoicePayPreviewSetupProps.previewOverrideRegistrationPackage.amountCents
      : undefined
  );
  const [lateFeeAmountShadow, setLateFeeAmountShadow] = useState(
    p.initialOrgInvoicePayPreviewSetupProps?.type === OrgInvoiceTypes.manual &&
      p.initialOrgInvoicePayPreviewSetupProps.testManualOrgInvoice?.lateFeeCentsToBeIssuedIfLate
      ? String(p.initialOrgInvoicePayPreviewSetupProps.testManualOrgInvoice?.lateFeeCentsToBeIssuedIfLate / 100)
      : p.initialOrgInvoicePayPreviewSetupProps?.type === OrgInvoiceTypes.registration &&
        p.initialOrgInvoicePayPreviewSetupProps.previewOverrideRegistrationPackage?.paymentInFullLateFeeAmountCents
      ? String(p.initialOrgInvoicePayPreviewSetupProps.previewOverrideRegistrationPackage.paymentInFullLateFeeAmountCents / 100)
      : ""
  );
  const [lateFeeamount, setLateFeeAmount] = useState<number | undefined>(
    p.initialOrgInvoicePayPreviewSetupProps?.type === OrgInvoiceTypes.manual &&
      p.initialOrgInvoicePayPreviewSetupProps.testManualOrgInvoice?.lateFeeCentsToBeIssuedIfLate
      ? p.initialOrgInvoicePayPreviewSetupProps.testManualOrgInvoice?.lateFeeCentsToBeIssuedIfLate
      : p.initialOrgInvoicePayPreviewSetupProps?.type === OrgInvoiceTypes.registration &&
        p.initialOrgInvoicePayPreviewSetupProps.previewOverrideRegistrationPackage?.paymentInFullLateFeeAmountCents
      ? p.initialOrgInvoicePayPreviewSetupProps.previewOverrideRegistrationPackage.paymentInFullLateFeeAmountCents
      : undefined
  );
  const [allowedPaymentPlanIds, setAllowedPaymentPlanIds] = useState<Record<string, true>>(
    p.initialOrgInvoicePayPreviewSetupProps?.type === OrgInvoiceTypes.manual
      ? p.initialOrgInvoicePayPreviewSetupProps.testManualOrgInvoice?.allowedPaymentPlanIds ?? {}
      : p.initialOrgInvoicePayPreviewSetupProps?.type === OrgInvoiceTypes.registration
      ? p.initialOrgInvoicePayPreviewSetupProps.previewOverrideRegistrationPackage?.supportedPaymentPlanIds ?? {}
      : {}
  );
  const testPlayerBundle: PlayerBundle = {
    virtualAthleteAccount: {
      email: "test@player.com",
      firstName: "Test",
      lastName: "Player"
    },
    createdAtMS: Date.now(),
    deletedAtMS: 0,
    derived: { linkedPlayers: {}, activeLinkedOrgs: {} },
    id: "test-player-player-bundle-id"
  };
  const testOrgManualInvoice: OrgInvoice__Manual | undefined =
    selectedOrgInvoiceType === OrgInvoiceTypes.manual
      ? {
          amountDueCents: amount ?? 0,
          createdAtMS: Date.now(),
          derivedTotalAmountDueCentsIncludingChildrenInvoices: amount ?? 0,
          derivedTotalAmountPaidCentsBeforeAllFees: 0,
          derivedTotalAmountPaidCentsIncludingChildrenInvoices: 0,
          dueDateMS: dueDateMS ?? moment().add(1, "month").valueOf(),
          id: "test-invoice",
          invoiceGroupId: "test-invoice",
          allowedPaymentPlanIds: Object.keys(allowedPaymentPlanIds)
            .filter(paymentPlanId => orgPaymentPlans?.find(opp => opp.id === paymentPlanId)?.type === OrgPaymentPlanType.monthly)
            .reduce((acc, val) => {
              acc[val] = true;
              return acc;
            }, {} as Record<string, true>),
          lateFeeCentsToBeIssuedIfLate: lateFeeamount ?? 0,
          memo: translate({ defaultMessage: "Test Invoice" }),
          orgId: p.org.id,
          playerBundleId: testPlayerBundle.id,
          type: OrgInvoiceTypes.manual,
          assignedByAccountId: getCurrentUserAccountId(),
          thisInvoicePaidInFullDateMS: 0
        }
      : undefined;
  const testOrgRegistrationInvoice: OrgInvoice__Registration | undefined =
    selectedOrgRegistrationPackage && selectedOrgSeason
      ? {
          id: "test-registration-invoice",
          invoiceGroupId: "test-registration-invoice",
          amountDueCents: selectedOrgRegistrationPackage.amountCents,
          createdAtMS: Date.now(),
          derivedTotalAmountDueCentsIncludingChildrenInvoices: selectedOrgRegistrationPackage.amountCents,
          derivedTotalAmountPaidCentsBeforeAllFees: 0,
          derivedTotalAmountPaidCentsIncludingChildrenInvoices: 0,
          dueDateMS: selectedOrgSeason.registrationDueDateMS,
          lateFeeCentsToBeIssuedIfLate: lateFeeamount ?? 0,
          memo: selectedOrgRegistrationPackage.name,
          orgId: p.org.id,
          orgSeasonId: selectedOrgRegistrationPackage.orgSeasonId,
          orgRegistrationPackageId: selectedOrgRegistrationPackage.id,
          playerBundleId: testPlayerBundle.id,
          type: OrgInvoiceTypes.registration,
          thisInvoicePaidInFullDateMS: 0
        }
      : undefined;
  const orgPaymentPlansForSelectedType =
    selectedOrgInvoiceType === OrgInvoiceTypes.manual
      ? orgPaymentPlans?.filter(opp => opp.type === OrgPaymentPlanType.monthly)
      : orgPaymentPlans;
  const orgRegistrationPackagesForSelectedOrgSeason = selectedOrgSeasonId
    ? orgRegistrationPackages?.filter(orp => orp.orgSeasonId === selectedOrgSeasonId)
    : [];

  let typeOptions: {
    label: string;
    value: string;
  }[] = [];
  if (p.allowedTypes === OrgInvoiceTypes.manual || p.allowedTypes === "both") {
    typeOptions.push({
      label: translate.common.Invoice,
      value: OrgInvoiceTypes.manual
    });
  }
  if (p.allowedTypes === OrgInvoiceTypes.registration || p.allowedTypes === "both") {
    typeOptions.push({
      label: translate.common.Registration,
      value: OrgInvoiceTypes.registration
    });
  }

  return (
    <Form
      children={isFormValid => {
        return (
          <FullScreenModal
            bottomButton={{
              title: translate.common.Done,
              enabled:
                isFormValid &&
                ((selectedOrgInvoiceType === OrgInvoiceTypes.manual && !!testOrgManualInvoice) ||
                  (selectedOrgInvoiceType === OrgInvoiceTypes.registration &&
                    selectedOrgRegistrationPackage &&
                    selectedOrgSeason &&
                    !!testOrgRegistrationInvoice)),

              onPress: async () => {
                try {
                  if (selectedOrgInvoiceType === OrgInvoiceTypes.manual && !!testOrgManualInvoice) {
                    p.onSave({
                      type: OrgInvoiceTypes.manual,
                      org: p.org,
                      orgSettings: p.orgSettings,
                      testManualOrgInvoice: testOrgManualInvoice
                    });
                  } else if (
                    selectedOrgInvoiceType === OrgInvoiceTypes.registration &&
                    selectedOrgRegistrationPackage &&
                    selectedOrgSeason &&
                    !!testOrgRegistrationInvoice
                  ) {
                    p.onSave({
                      type: OrgInvoiceTypes.registration,
                      org: p.org,
                      orgSettings: p.orgSettings,
                      testRegistrationOrgInvoice: testOrgRegistrationInvoice,
                      orgSeason: selectedOrgSeason,
                      previewOverrideRegistrationPackage: selectedOrgRegistrationPackage
                    });
                  } else {
                    p.onSave();
                  }
                  p.onRequestDismiss();
                } catch (e) {}
              }
            }}
            onRequestDismiss={p.onRequestDismiss}
            title={translate({ defaultMessage: "Preview Example Data Setup" })}
          >
            <StyledText>
              {translate({
                defaultMessage:
                  "In order to preview how a coupon or payment plan will work, use the following controls to create example data for an invoice or a registration."
              })}
            </StyledText>
            <PrettyCoolSelectInput
              label={translate({ defaultMessage: "Payment Type" })}
              value={selectedOrgInvoiceType}
              containerStyle={{ marginTop: 16 }}
              isRequired
              options={typeOptions}
              onChange={newVal => {
                setSelectedOrgInvoiceType(newVal as OrgInvoiceTypes.manual | OrgInvoiceTypes.registration);
              }}
            />
            {selectedOrgInvoiceType === OrgInvoiceTypes.manual ? (
              <View>
                <PrettyCoolTextInputWithLabel
                  style={{ marginTop: 16 }}
                  label={translate.common.Amount}
                  isMoney
                  isRequired
                  onChange={newVal => {
                    const newAmt = newVal?.match(/\d+\.?\d?\d?/)?.[0];
                    if (!newVal) {
                      setAmountShadow("");
                      setAmount(undefined);
                      return;
                    }

                    if (parseFloat(newAmt || "0") < 0) {
                      return;
                    }
                    setAmountShadow(newAmt || "");
                    setAmount(newAmt ? Number(newAmt) * 100 : 0);
                  }}
                  inputProps={{ type: "number" }}
                  value={amountShadow}
                />
              </View>
            ) : (
              <View>
                <PrettyCoolSelectInput
                  isRequired
                  containerStyle={{ marginTop: 16 }}
                  label={translate.common.Season}
                  onChange={newVal => {
                    if (newVal !== selectedOrgSeasonId) {
                      setSelectedOrgSeasonId(newVal ?? "");
                      setSelectedOrgRegistrationPackageId("");
                    }
                  }}
                  options={
                    orgSeasons?.map(os => {
                      return {
                        label: os.name,
                        value: os.id
                      };
                    }) ?? []
                  }
                  placeholder={translate.common.SelectDotDotDot}
                  value={selectedOrgSeasonId}
                />
                <PrettyCoolSelectInput
                  isRequired
                  containerStyle={{ marginTop: 16 }}
                  value={selectedOrgRegistrationPackageId}
                  onChange={newVal => {
                    if (newVal !== selectedOrgRegistrationPackageId) {
                      setSelectedOrgRegistrationPackageId(newVal ?? "");
                      const selectedOrgRegistrationPackage = orgRegistrationPackages?.find(orp => orp.id === newVal);
                      if (selectedOrgRegistrationPackage) {
                        setAllowedPaymentPlanIds(selectedOrgRegistrationPackage.supportedPaymentPlanIds ?? {});
                      }
                    }
                  }}
                  label={translate.common.RegistrationPackage}
                  options={
                    orgRegistrationPackagesForSelectedOrgSeason?.map(orp => {
                      return {
                        label: orp.name,
                        value: orp.id
                      };
                    }) ?? []
                  }
                  placeholder={
                    !selectedOrgSeasonId
                      ? translate({ defaultMessage: "Select a season first" })
                      : translate.common.SelectDotDotDot
                  }
                />
              </View>
            )}
            {orgPaymentPlansForSelectedType?.length ? (
              <PrettyCoolMultiSelectWithLabel
                disabled={!!p.previewOrgPaymentPlan || selectedOrgInvoiceType === OrgInvoiceTypes.registration}
                style={{ marginTop: 16 }}
                label={translate({ defaultMessage: "Allowed Payment Plans" })}
                infoTooltip={
                  selectedOrgInvoiceType === OrgInvoiceTypes.registration
                    ? translate({ defaultMessage: "For registration, this comes from the package." })
                    : translate({ defaultMessage: "Select which payment plans should be available in the preview." })
                }
                value={
                  p.previewOrgPaymentPlan
                    ? [
                        {
                          label: p.previewOrgPaymentPlan.name,
                          value: p.previewOrgPaymentPlan.id
                        }
                      ]
                    : _.compact(
                        Object.keys(allowedPaymentPlanIds).map(paymentPlanId => {
                          const orgPaymentPlan = orgPaymentPlansForSelectedType?.find(opp => opp.id === paymentPlanId);
                          if (orgPaymentPlan) {
                            return {
                              value: paymentPlanId,
                              label: orgPaymentPlan.name
                            };
                          }
                          return null;
                        })
                      )
                }
                onChange={newVal => {
                  setAllowedPaymentPlanIds(
                    newVal.reduce((acc, val) => {
                      acc[val.value] = true;
                      return acc;
                    }, {} as Record<string, true>)
                  );
                }}
                options={_.orderBy(orgPaymentPlansForSelectedType ?? [], opp => opp.name, "asc").map(opp => {
                  return {
                    label: opp.name,
                    value: opp.id
                  };
                })}
              />
            ) : null}
            <View style={{ marginTop: 16 }}>
              <PrettyCoolDateInput
                isRequired
                onChange={newVal => {
                  if (newVal) {
                    setDueDateMS(newVal.valueOf());
                  }
                }}
                infoTooltip={translate({
                  defaultMessage: "For registration, this comes from the season."
                })}
                disabled={selectedOrgInvoiceType === OrgInvoiceTypes.registration}
                value={moment(dueDateMS).toDate()}
                label={translate.common.DueDate}
              />
            </View>
            <PrettyCoolTextInputWithLabel
              style={{ marginTop: 16 }}
              label={translate.common.LateFee}
              isMoney
              onChange={newVal => {
                const newAmt = newVal?.match(/\d+\.?\d?\d?/)?.[0];
                if (!newVal) {
                  setLateFeeAmountShadow("");
                  setLateFeeAmount(undefined);
                  return;
                }

                if (parseFloat(newAmt || "0") < 0) {
                  return;
                }
                setLateFeeAmountShadow(newAmt || "");
                setLateFeeAmount(newAmt ? Number(newAmt) * 100 : 0);
              }}
              infoTooltip={
                selectedOrgInvoiceType
                  ? translate({ defaultMessage: "For registration, this comes from the package." })
                  : undefined
              }
              inputProps={{ type: "number", disabled: selectedOrgInvoiceType === OrgInvoiceTypes.registration }}
              value={lateFeeAmountShadow}
            />
          </FullScreenModal>
        );
      }}
    />
  );
}

export function OrgInvoicePreviewForCouponOrPaymentPlan(
  p: { org: Org; orgSettings: OrgSettings } & (
    | {
        type: "coupon";
        orgCoupon: OrgCoupon;
      }
    | { type: "paymentPlan"; orgPaymentPlan: OrgPaymentPlan }
  )
) {
  const [previewProps, setPreviewProps] = useState<OrgInvoicePreviewInnerProps | undefined>();

  let arePreviewPropsValidForType = false;
  if (p.type === "paymentPlan") {
    arePreviewPropsValidForType = true;
  } else {
    if (
      p.orgCoupon.useType === OrgCouponUseType.all ||
      (p.orgCoupon.useType === OrgCouponUseType.invoices && previewProps?.type === OrgInvoiceTypes.manual) ||
      (p.orgCoupon.useType === OrgCouponUseType.registration && previewProps?.type === OrgInvoiceTypes.registration)
    ) {
      arePreviewPropsValidForType = true;
    }
  }
  return (
    <View style={{ marginTop: 16 }}>
      <TextButton
        iconElm={<Cog6ToothIcon width={16} height={16} className="text-green-400" />}
        text={translate({ defaultMessage: "Set up example data" })}
        onPress={() => {
          if (p.type === "coupon") {
            if (p.orgCoupon.useType === OrgCouponUseType.registration) {
              openOrgInvoicePayPreviewSetup({
                initialType: OrgInvoiceTypes.registration,
                initialOrgInvoicePayPreviewSetupProps: previewProps,
                allowedTypes: OrgInvoiceTypes.registration,
                org: p.org,
                orgSettings: p.orgSettings,
                onSave(val) {
                  setPreviewProps(val);
                }
              });
            } else {
              openOrgInvoicePayPreviewSetup({
                initialType: OrgInvoiceTypes.manual,
                initialOrgInvoicePayPreviewSetupProps: previewProps,
                allowedTypes: p.orgCoupon.useType === OrgCouponUseType.invoices ? OrgInvoiceTypes.manual : "both",
                org: p.org,
                orgSettings: p.orgSettings,
                onSave(val) {
                  setPreviewProps(val);
                }
              });
            }
          } else {
            openOrgInvoicePayPreviewSetup({
              initialOrgInvoicePayPreviewSetupProps: previewProps,
              initialType:
                p.orgPaymentPlan.type !== OrgPaymentPlanType.monthly ? OrgInvoiceTypes.registration : OrgInvoiceTypes.manual,
              allowedTypes: p.orgPaymentPlan.type !== OrgPaymentPlanType.monthly ? OrgInvoiceTypes.registration : "both",
              org: p.org,
              orgSettings: p.orgSettings,
              onSave(val) {
                setPreviewProps(val);
              },
              previewOrgPaymentPlan: p.orgPaymentPlan
            });
          }
        }}
        textClassName="text-green-400"
        className="mb-4"
      />
      {!previewProps ? (
        <StyledText>{translate({ defaultMessage: "Please set up example data in order to preview" })}</StyledText>
      ) : !arePreviewPropsValidForType ? (
        <StyledText>
          {translate({
            defaultMessage: `The example data is not valid. If the coupon is an "invoice" type, the example data must be invoice data. If the coupon is a "registration" type, the data must be registration data`
          })}
        </StyledText>
      ) : (
        <OrgInvoicePayPreviewInner
          {...previewProps}
          previewOverrideOrgPaymentPlan={p.type === "paymentPlan" ? p.orgPaymentPlan : undefined}
          previewOverrideOrgCoupon={p.type === "coupon" ? p.orgCoupon : undefined}
        />
      )}
    </View>
  );
}

export function OrgInvoicePayPreviewInner(p: OrgInvoicePreviewInnerProps) {
  const [paymentDateMS, setPaymentDateMS] = useState(Date.now());
  const [selectedPaymentMethodType, setSelectedPaymentMethodType] = useState(PaymentMethodType.card);
  const testPlayerBundle: PlayerBundle = {
    virtualAthleteAccount: {
      email: "test@player.com",
      firstName: "Test",
      lastName: "Player"
    },
    createdAtMS: Date.now(),
    deletedAtMS: 0,
    derived: { linkedPlayers: {}, activeLinkedOrgs: {} },
    id: "test-player-player-bundle-id"
  };
  return (
    <View style={{ marginTop: 16 }}>
      <CoolDateInput
        onChange={newVal => {
          if (newVal) {
            setPaymentDateMS(newVal.valueOf());
          }
        }}
        infoTooltip={translate({
          defaultMessage: "Adjust this date to see a preview of how everything would appear when checking out on that day."
        })}
        value={moment(paymentDateMS).toDate()}
        label={translate({ defaultMessage: "Checkout Date" })}
      />
      <CoolSelectInput
        containerStyle={{ marginTop: 16 }}
        onChange={newVal => {
          if (newVal && (newVal === PaymentMethodType.bank || newVal === PaymentMethodType.card)) {
            setSelectedPaymentMethodType(newVal);
          }
        }}
        infoTooltip={translate({
          defaultMessage:
            "Adjust this to see a preview of how everything would appear when checking using different payment method types."
        })}
        value={selectedPaymentMethodType}
        label={translate({ defaultMessage: "Payment Method Type" })}
        options={[
          {
            label: translate.common.CreditDebitCardShort,
            value: PaymentMethodType.card
          },
          {
            label: translate.common.BankAccount,
            value: PaymentMethodType.bank
          }
        ]}
      />
      <Form
        children={isValid => {
          return (
            <View style={{ marginBottom: 30, marginTop: 16 }}>
              {p.type === OrgInvoiceTypes.manual ? (
                <OrgInvoicePay
                  isPreview
                  isEverythingElseValid
                  onComplete={async () => {}}
                  org={p.org}
                  orgSettings={p.orgSettings}
                  orgPaymentInvoiceCredits={[]}
                  playerBundle={testPlayerBundle}
                  type="manual"
                  orgInvoice={p.testManualOrgInvoice}
                  previewOverridePaymentDateMS={paymentDateMS}
                  previewOverrideOrgCoupon={p.previewOverrideOrgCoupon}
                  previewOverrideOrgPaymentPlan={p.previewOverrideOrgPaymentPlan}
                  previewOverridePaymentMethodType={selectedPaymentMethodType}
                />
              ) : (
                <OrgInvoicePay
                  isPreview
                  isEverythingElseValid
                  onComplete={async () => {}}
                  org={p.org}
                  orgSettings={p.orgSettings}
                  orgPaymentInvoiceCredits={[]}
                  playerBundle={testPlayerBundle}
                  type="registration"
                  orgInvoice={p.testRegistrationOrgInvoice}
                  orgSeason={p.orgSeason}
                  previewOverridePaymentDateMS={paymentDateMS}
                  previewOverrideRegistrationPackage={p.previewOverrideRegistrationPackage}
                  previewOverrideOrgCoupon={p.previewOverrideOrgCoupon}
                  previewOverrideOrgPaymentPlan={p.previewOverrideOrgPaymentPlan}
                  previewOverridePaymentMethodType={selectedPaymentMethodType}
                />
              )}
            </View>
          );
        }}
      />
    </View>
  );
}
