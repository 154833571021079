import { getUniversalHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";

export async function org__server__getOrgSecretSummary(p: { selfAccountId: string; orgId: string }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const [org, orgSecret] = await Promise.all([h.Org.getDoc(p.orgId), h.OrgSecret.getDoc(p.orgId)]);

  if (!org?.accounts[p.selfAccountId]) {
    throw new Error("Do not have permission to access!");
  }

  const uploaderAccount = orgSecret?.merchantOnboardingInfo?.uploaderAccountId
    ? await h.Account.getDoc(orgSecret?.merchantOnboardingInfo?.uploaderAccountId)
    : null;

  return {
    merchantOnboardingInfo: orgSecret?.merchantOnboardingInfo
      ? {
          uploadedAtMS: orgSecret.merchantOnboardingInfo.uploadedAtMS,
          uploaderAccount: uploaderAccount
        }
      : null,
    hasApiKey: !!orgSecret?.nmiConfig?.encryptedMerchantApiKey
  };
}

org__server__getOrgSecretSummary.auth = async (req: any) => {
  await validateTokenAndEnsureSelfAccountIdMatches(req);
};
