import { Typography } from "@material-ui/core";
import _ from "lodash";
import { PlayerBundle } from "@ollie-sports/models";
import { translate } from "@ollie-sports/i18n";
import { COLORS } from "@ollie-sports/core";
import { useState } from "react";
import { Form, PrettyCoolTextInputWithLabel } from "../../components/Form";
import { openModal } from "../../components/modals/imperativeModal";
import { FullScreenModal } from "../../components/modals/getFullscreenModal";
import { openSuccessToast } from "../../utils/openErrorToast";
import { getBifrost } from "../../services/bifrost.service";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";

type PropsForParentInvoiceCredit = {
  orgId: string;
  orgRegistrationId: string;
  playerBundle: PlayerBundle;
  onComplete: () => Promise<void>;
};

export function openOrgRegistrationDeleteConfirm(p: PropsForParentInvoiceCredit) {
  return new Promise<string | undefined>(res => {
    const modal = openModal({
      body: (
        <OrgRegistrationDeleteConfirm
          {...p}
          onRequestDismiss={() => {
            modal.close();
          }}
        />
      )
    });
  });
}

function OrgRegistrationDeleteConfirm(p: PropsForParentInvoiceCredit & { onRequestDismiss: () => void }) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [verificationText, setVerificationText] = useState("");
  const [isUpdatingPaymentMethod, setIsUpdatingPaymentMethod] = useState(false);
  const playerName = `${p.playerBundle.virtualAthleteAccount.firstName.trim()} ${p.playerBundle.virtualAthleteAccount.lastName.trim()}`;

  return (
    <Form
      children={isFormValid => {
        return (
          <FullScreenModal
            title={translate({ defaultMessage: "Delete Registration" })}
            bottomButton={{
              title: translate.common.Delete,
              enabled: isFormValid,
              onPress: async () => {
                setIsLoading(true);
                try {
                  await getBifrost().orgRegistration__server__deleteOrgRegistration.fetchServer({
                    orgId: p.orgId,
                    orgRegistrationId: p.orgRegistrationId,
                    selfAccountId: getCurrentUserAccountId()
                  });
                  await p.onComplete();
                  openSuccessToast();
                  p.onRequestDismiss();
                } catch (e) {
                  setErrorMsg(
                    translate({
                      defaultMessage:
                        "There was a problem deleting the registration. Please try again or contact support@olliesports.com"
                    })
                  );
                }
                setIsLoading(false);
              }
            }}
            onRequestDismiss={() => {
              p.onRequestDismiss();
            }}
          >
            <div>
              <div>
                <div className="mt-4">
                  {translate({
                    defaultMessage:
                      "When deleting a registration, past payments will kept for record, but the player will not be tracked as registered."
                  })}
                </div>
                <div className="mb-1 mt-4 leading-tight">
                  <div className="font-bold">{`${translate({ defaultMessage: "Player Name" })}`}</div>
                  <div>{playerName}</div>
                </div>
                <div className="mt-4">
                  {translate({
                    defaultMessage: "Please type the player's name below to confirm you want to delete the registration."
                  })}
                </div>
                <PrettyCoolTextInputWithLabel
                  style={{ marginTop: 16 }}
                  label={translate({ defaultMessage: "Confirm Player Name" })}
                  onChange={newVal => {
                    setVerificationText(newVal ?? "");
                  }}
                  validate={val => {
                    if (!val) {
                      return translate.common.IsRequired;
                    }
                    if (val.toLowerCase().replace(" ", "") !== playerName.toLowerCase().replace(" ", "")) {
                      return translate({ defaultMessage: "Name doesn't match" });
                    }
                    return "";
                  }}
                  placeholder={playerName}
                  value={verificationText}
                />
              </div>
              {errorMsg ? <Typography style={{ color: COLORS.red, marginTop: 16 }}>{errorMsg}</Typography> : null}
            </div>
          </FullScreenModal>
        );
      }}
    />
  );
}
