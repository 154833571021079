import { Org, OrgSettings } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";

export async function org__server__updateOrgSettings(p: { id: string; orgSettings: Partial<OrgSettings> }) {
  // SERVER_ONLY_TOGGLE

  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.OrgSettings.update({ id: p.id, doc: p.orgSettings });

  // SERVER_ONLY_TOGGLE
}

org__server__updateOrgSettings.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};
// i18n certified - complete
