import { Typography } from "@material-ui/core";
import { View } from "react-native-web";
import _ from "lodash";
import { Org, OrgSeason } from "@ollie-sports/models";
import { dateFormatters, getCurrentLocale, translate } from "@ollie-sports/i18n";
import { getBifrost } from "../../services/bifrost.service";
import { COLORS, DistributiveOmit, computeRollOverPackageInfo } from "@ollie-sports/core";
import { useState } from "react";
import {
  Form,
  PrettyCheckbox,
  PrettyCoolDateInput,
  PrettyCoolSelectInput,
  PrettyCoolTextInput,
  PrettyCoolTextInputWithLabel
} from "../../components/Form";
import moment from "moment";
import { StyledButton } from "../../components/StyledButton";
import { openModal } from "../../components/modals/imperativeModal";
import { useImmutableState } from "../../utils/useImmutableState";
import getFullScreenModal, { FullScreenModal } from "../../components/modals/getFullscreenModal";
import { dequal } from "dequal";
import { CoolCheckboxInput } from "../../components/Inputs/CoolCheckboxInput";
import { useOrgSeasons } from "../../hooks/useOrgSeasons";
import { CoolSelectInput } from "../../components/Inputs/CoolSelectInput";
import { StyledText } from "../../components/StyledText";
import { useOrgRegistrationPackages } from "../../hooks/useOrgRegistrationPackages";
import { PressableAsterisksText } from "../../components/PressableAsterisksText";
import { ArrowRight } from "react-feather";
import { useSystemStatus } from "../../hooks/useSystemStatus";
import { useOrgTeamsWithSettingsByTeamId } from "../../hooks/useOrgTeamsWithSettingsByTeamId";
import getConfirm from "../../components/modals/getConfirm";

type Props = {
  type: "create" | "edit";
  org: Org;
  initialOrgSeason?: OrgSeason;
};

export function openOrgSeasonsAddEditModal(p: Props) {
  return new Promise<string | undefined>(res => {
    const modal = openModal({
      body: (
        <OrgSeasonsAddEditModal
          {...p}
          onRequestDismiss={orgSeasonId => {
            modal.close();
            res(orgSeasonId);
          }}
        />
      )
    });
  });
}

function OrgSeasonsAddEditModal(p: Props & { onRequestDismiss: (orgSeasonId: string | undefined) => void }) {
  const initialOrgSeason = p.initialOrgSeason ?? {
    orgId: p.org.id,
    isActive: true
  };
  const [orgSeason, setOrgSeason] = useImmutableState<Partial<OrgSeason>>(initialOrgSeason);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const orgSeasons = useOrgSeasons({ orgId: p.org.id });
  const [shouldRollOverRegistrationPackages, setShouldRollOverRegistrationPackages] = useState(false);
  const registrationPackages = useOrgRegistrationPackages({ orgId: p.org.id });
  const [selectedOrgSeasonIdForPackageDuplication, setSelectedOrgSeasonIdForPackageDuplication] = useState("");
  const teamsWithTeamSettings = useOrgTeamsWithSettingsByTeamId({ orgId: p.org.id });
  const overlappingSeasons = orgSeasons?.filter(
    o => o.id !== orgSeason.id && orgSeason.startDateMS! >= o.startDateMS && orgSeason.startDateMS! <= o.endDateMS
  );

  const teamsWithAssignedPackagesInThisSeason = Object.values(teamsWithTeamSettings ?? {})
    .map(a => a.settings)
    .filter(b => orgSeason?.id && !!b.registrationPackageIdBySeasonId?.[orgSeason.id]);
  const numTeamsWithAssignedPackages = teamsWithAssignedPackagesInThisSeason.length;

  const systemStatus = useSystemStatus();
  const paymentAvailabilityDateMS = systemStatus?.paymentAvailabilityDateMS ?? 1714579200000;
  const nowMS = Date.now();
  const [isRequiringTeamsToConfirmReadiness, setIsRequiringTeamsToConfirmReadiness] = useState(
    !!(p.initialOrgSeason && !!p.initialOrgSeason.registrationHardStartDateMS)
  );

  const rollingOverRegistrationPackages =
    registrationPackages?.filter(a => a.orgSeasonId === selectedOrgSeasonIdForPackageDuplication) || [];
  return (
    <Form
      children={isFormValid => {
        return (
          <FullScreenModal
            title={
              p.type === "create" ? translate({ defaultMessage: "Create Season" }) : translate({ defaultMessage: "Edit Season" })
            }
            bottomButton={{
              title: p.type === "edit" ? translate.common.Save : translate.common.Create,
              enabled: isFormValid,
              onPress: async () => {
                if (
                  p.initialOrgSeason?.startDateMS &&
                  orgSeason.startDateMS &&
                  p.initialOrgSeason.startDateMS !== orgSeason.startDateMS &&
                  p.initialOrgSeason.startDateMS > Date.now() &&
                  orgSeason.startDateMS < Date.now() &&
                  !orgSeason.registrationHardStartDateMS &&
                  numTeamsWithAssignedPackages
                ) {
                  const confirm = await getConfirm({
                    title: translate({ defaultMessage: "Are you sure?" }),
                    subtitle: translate(
                      {
                        defaultMessage:
                          "There are {numTeams} {numTeams, plural, one {team} other {teams}} that have assigned packages. If you save with the specified start date, registration will immediately open for all of these teams since you are not requiring team staff to confirm the team's readiness."
                      },
                      { numTeams: numTeamsWithAssignedPackages }
                    ),
                    confirmButtonColor: "red"
                  });
                  if (!confirm) {
                    return;
                  }
                }
                setIsLoading(true);
                if (p.type === "create") {
                  const newOrgSeason: DistributiveOmit<OrgSeason, "id" | "createdAtMS"> = { ...orgSeason } as DistributiveOmit<
                    OrgSeason,
                    "id" | "createdAtMS"
                  >;
                  try {
                    const { data: newOrgSeasonId } = await getBifrost().orgSeason__client__addOrgSeason.fetchClient({
                      orgSeason: newOrgSeason,
                      orgSeasonIdForRegistrationPackageDuplication: selectedOrgSeasonIdForPackageDuplication
                    });
                    p.onRequestDismiss(newOrgSeasonId);
                  } catch (e) {
                    console.error(e);
                    setErrorMsg(
                      translate({
                        defaultMessage:
                          "There was a problem creating the season. Please try again or contact support@olliesports.com"
                      })
                    );
                  }
                } else {
                  try {
                    const newOrgSeason = orgSeason as OrgSeason;
                    if (!isRequiringTeamsToConfirmReadiness) {
                      delete newOrgSeason.registrationHardStartDateMS;
                    }
                    await getBifrost().orgSeason__client__updateOrgSeason.fetchClient({
                      orgSeason: newOrgSeason
                    });
                    p.onRequestDismiss(undefined);
                  } catch (e) {
                    setErrorMsg(
                      translate({
                        defaultMessage:
                          "There was a problem updating the season. Please try again or contact support@olliesports.com"
                      })
                    );
                  }
                }
                setIsLoading(false);
              }
            }}
            onRequestDismiss={() => {
              if (
                !dequal(orgSeason, initialOrgSeason) &&
                !window.confirm(translate({ defaultMessage: "You have unsaved changes. Are you sure you wish to leave?" }))
              ) {
                return;
              }

              p.onRequestDismiss(undefined);
            }}
          >
            <div className="flex-1">
              {paymentAvailabilityDateMS > nowMS ? (
                <div className="mt-2 mb-4">
                  {translate(
                    {
                      defaultMessage:
                        "NOTE: You can currently only create seasons that begin after {date}. This date may move up. We appreciate your patience as we finish up our new registration platform!"
                    },
                    { date: dateFormatters.mm_dd_yyyy(moment(paymentAvailabilityDateMS).toDate(), getCurrentLocale()) }
                  )}
                </div>
              ) : null}
              <PrettyCoolTextInputWithLabel
                label={translate({ defaultMessage: "Season Name" })}
                value={orgSeason.name}
                isRequired
                inputProps={{
                  placeholder: `${moment().year()}/${moment().year() + 1}...`
                }}
                onChange={newVal => {
                  setOrgSeason({ name: newVal });
                }}
              />
              <div className="flex flex-1 mt-7">
                <div className="flex-1 mr-2">
                  <PrettyCoolDateInput
                    value={orgSeason.startDateMS ? moment(orgSeason.startDateMS).toDate() : undefined}
                    onChange={newVal => {
                      if (newVal) {
                        setOrgSeason({ startDateMS: moment(newVal).valueOf() });
                      } else {
                        setOrgSeason({ startDateMS: undefined });
                      }
                    }}
                    placeholderText={translate.common.SelectDotDotDot}
                    isRequired
                    validate={val => {
                      if (!val) {
                        return translate.common.IsRequired;
                      }
                      if (val && orgSeason.endDateMS && val >= moment(orgSeason.endDateMS).toDate()) {
                        return translate({ defaultMessage: "Start Date must be before End Date" });
                      }
                      return "";
                    }}
                    className="flex-1"
                    label={translate.common.StartDate}
                  />
                </div>
                <div className="flex-1 ml-2">
                  <PrettyCoolDateInput
                    value={orgSeason.endDateMS ? moment(orgSeason.endDateMS).toDate() : undefined}
                    isRequired
                    className="flex-1"
                    placeholderText={translate.common.SelectDotDotDot}
                    onChange={newVal => {
                      if (newVal) {
                        setOrgSeason({ endDateMS: moment(newVal).valueOf() });
                      } else {
                        setOrgSeason({ endDateMS: undefined });
                      }
                    }}
                    label={translate.common.EndDate}
                  />
                </div>
              </div>

              <div className="flex-1 mt-7">
                <PrettyCoolDateInput
                  value={orgSeason.registrationDueDateMS ? moment(orgSeason.registrationDueDateMS).toDate() : undefined}
                  isRequired
                  className="flex-1"
                  onChange={newVal => {
                    if (newVal) {
                      setOrgSeason({ registrationDueDateMS: moment(newVal).endOf("day").valueOf() });
                    } else {
                      setOrgSeason({ registrationDueDateMS: undefined });
                    }
                  }}
                  infoTooltip={translate({
                    defaultMessage:
                      "After this date, players will be considered ineligible if they have not completed registration. Additionally, any late fees that are set on the registration packages will take effect."
                  })}
                  validate={val => {
                    if (!val) {
                      return translate.common.IsRequired;
                    }
                    if (val && orgSeason.startDateMS && val <= moment(orgSeason.startDateMS).toDate()) {
                      return translate({ defaultMessage: "Due Date must be after Start Date" });
                    }
                    return "";
                  }}
                  placeholderText={translate.common.SelectDotDotDot}
                  label={translate({ defaultMessage: "Registration Due Date" })}
                />
              </div>

              <div>
                {
                  <CoolCheckboxInput
                    style={{ marginTop: 16 }}
                    label={translate({ defaultMessage: "Require team staff to confirm roster readiness?" })}
                    labelType="inside"
                    infoTooltip={translate({
                      defaultMessage:
                        "Requires a team staff member for each team that has an assigned package to open registration for their team by confirming their roster is up to date for the season."
                    })}
                    onChange={newVal => {
                      setIsRequiringTeamsToConfirmReadiness(newVal);
                    }}
                    value={isRequiringTeamsToConfirmReadiness}
                  />
                }
              </div>
              {isRequiringTeamsToConfirmReadiness ? (
                <div className="flex-1 mt-4">
                  <PrettyCoolDateInput
                    required
                    value={
                      orgSeason.registrationHardStartDateMS ? moment(orgSeason.registrationHardStartDateMS).toDate() : undefined
                    }
                    className="flex-1"
                    onChange={newVal => {
                      if (newVal) {
                        setOrgSeason({ registrationHardStartDateMS: moment(newVal).startOf("day").valueOf() });
                      } else {
                        setOrgSeason({ registrationHardStartDateMS: undefined });
                      }
                    }}
                    label={`${translate({ defaultMessage: "Registration Start Failsafe Date" })}`}
                    infoTooltip={translate({
                      defaultMessage:
                        "After this date, all teams will see the registration banner regardless of whether the team staff has confirmed roster readiness."
                    })}
                    validate={val => {
                      if (!val) {
                        return translate.common.IsRequired;
                      }
                      if (val && orgSeason.startDateMS && val <= moment(orgSeason.startDateMS).toDate()) {
                        return translate({ defaultMessage: "Must be after Start Date" });
                      }
                      return "";
                    }}
                    placeholderText={translate.common.SelectDotDotDot}
                  />
                </div>
              ) : null}
              {registrationPackages?.length && p.type === "create" ? (
                <CoolCheckboxInput
                  label={translate({ defaultMessage: "Roll over registration packages from a previous season?" })}
                  value={shouldRollOverRegistrationPackages}
                  style={{ marginTop: 10 }}
                  labelType="inside"
                  onChange={() => setShouldRollOverRegistrationPackages(a => !a)}
                />
              ) : null}
              {/*Only hide this part since it jacks up styling if its removed from the DOM */}
              <div style={{ height: shouldRollOverRegistrationPackages ? undefined : 0.1, overflow: "hidden" }}>
                {shouldRollOverRegistrationPackages ? (
                  <PrettyCoolSelectInput
                    containerStyle={{ marginTop: 8 }}
                    placeholder={translate({ defaultMessage: "Select season..." })}
                    allowClear
                    isRequired
                    onChange={newVal => {
                      setSelectedOrgSeasonIdForPackageDuplication(newVal);
                    }}
                    value={selectedOrgSeasonIdForPackageDuplication}
                    options={(orgSeasons ?? [])
                      .filter(os => !os.archivedAtMS)
                      .map(os => {
                        return {
                          label: os.name,
                          value: os.id
                        };
                      })}
                  />
                ) : null}
                <div className="mt-1">
                  <span className="text-gray-500 text-sm">
                    {translate({
                      defaultMessage:
                        'Registration packages from the selected prior season will be duplicated and years will be incremented by one. E.g. The registration package "2009/U15 Premier" will become "2010/U15 Premier"'
                    })}
                  </span>
                </div>

                {selectedOrgSeasonIdForPackageDuplication ? (
                  <div className="mt-4 mb-2">
                    <PressableAsterisksText
                      asterisksClassName="underline text-blue-400 cursor-context-menu"
                      onAsteriskPress={() => {
                        getFullScreenModal({
                          title: translate(
                            { defaultMessage: "{season} Registration Packages" },
                            { season: orgSeason.name || "" }
                          ),
                          children: (
                            <div>
                              {_.orderBy(rollingOverRegistrationPackages, a => a.name).map(pkg => {
                                const { name: newName } = computeRollOverPackageInfo(pkg);
                                return (
                                  <div key={pkg.id}>
                                    <div className="my-2 inline-flex items-center">
                                      {pkg.name !== newName ? (
                                        <>
                                          {pkg.name} <ArrowRight className="inline mr-1" />
                                        </>
                                      ) : null}
                                      {newName}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )
                        });
                      }}
                    >
                      {translate(
                        {
                          defaultMessage:
                            "Duplicating and incrementing *{numPackages} {numPackages, plural, one {registration package} other {registration packages}}* from {priorSeason} season."
                        },
                        {
                          numPackages: rollingOverRegistrationPackages.length,
                          priorSeason: orgSeasons?.find(s => s.id === selectedOrgSeasonIdForPackageDuplication)?.name || ""
                        }
                      )}
                    </PressableAsterisksText>
                  </div>
                ) : null}
              </div>

              {errorMsg ? <Typography style={{ color: COLORS.red, marginTop: 30 }}>{errorMsg}</Typography> : null}
            </div>
          </FullScreenModal>
        );
      }}
    />
  );
}
