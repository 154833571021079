import { Typography, useTheme, useMediaQuery, Box } from "@material-ui/core";
import { View } from "react-native-web";
import _ from "lodash";
import { Org, Team, TeamSettings } from "@ollie-sports/models";
import { CenteredLoader } from "../../components/CenteredLoader";
import { translate } from "@ollie-sports/i18n";
import { useParams } from "react-router-dom";

import { useOrgTeamsWithSettingsByTeamId } from "../../hooks/useOrgTeamsWithSettingsByTeamId";
import { TeamsTable } from "../../components/TeamsTable";
import { useOrg } from "../../hooks/useOrg";

export default function OrgTeamSummary() {
  const params: any = useParams();
  const orgId = params.orgId;

  const { org } = useOrg({ orgId });
  const orgTeamsWithSettingsByTeamId = useOrgTeamsWithSettingsByTeamId({ orgId });

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {!orgTeamsWithSettingsByTeamId || !org ? (
          <CenteredLoader />
        ) : (
          <OrgTeamSummaryInner org={org} orgTeamsWithSettingsByTeamId={orgTeamsWithSettingsByTeamId} />
        )}
      </View>
    </Box>
  );
}

function OrgTeamSummaryInner(p: {
  org: Org;
  orgTeamsWithSettingsByTeamId: Record<string, { team: Team; settings: TeamSettings }>;
}) {
  return (
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: "row" }}>
        <h1 className="text-2xl sm:text-4xl flex-1 mt-4">
          {translate({ defaultMessage: "{orgName} Teams" }, { orgName: p.org.name })}
        </h1>
      </View>
      <TeamsTable org={p.org} teamsWithSettingsByTeamId={p.orgTeamsWithSettingsByTeamId} showExtraDetails />
    </View>
  );
}
