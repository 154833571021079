import { Typography, Box, SvgIcon } from "@material-ui/core";
import { TouchableOpacity, View } from "react-native-web";
import _ from "lodash";
import { Org, OrgRegistrationTeamSummary } from "@ollie-sports/models";
import { CenteredLoader } from "../../components/CenteredLoader";
import { getCurrentLocale, translate } from "@ollie-sports/i18n";
import { useLocation, useParams } from "react-router-dom";
import { useOrg } from "../../hooks/useOrg";
import { ShadowView } from "../../components/ShadowView";
import CoolerTable, { CoolerTableColumnDef, CoolerTableMethods } from "../../components/CoolerTable";
import { TableSectionWrapper } from "./components/TableSectionWrapper";
import { BackButton } from "../../components/BackButton";
import { getBifrost } from "../../services/bifrost.service";
import { usePersistentState } from "../../utils/usePersistentState";
import { CoolSelectInput } from "../../components/Inputs/CoolSelectInput";
import { useOrgSeasons } from "../../hooks/useOrgSeasons";
import { useOrgTeams } from "../../hooks/useOrgTeams";
import { COLORS, combineArrayWithCommasAndAnd, formatMoneyCentsToDollarCentPrettyString } from "@ollie-sports/core";
import { useEffect, useRef, useState } from "react";
import { CoolMultiSelectInput } from "../../components/Inputs/CoolMultiSelectInput";
import { CoolTextInput } from "../../components/Inputs/CoolTextInput";
import { useOrgTeamTags } from "../../hooks/useOrgTeamTags";
import getFullScreenModal from "../../components/modals/getFullscreenModal";
import { EyeIcon } from "@heroicons/react/24/outline";
import { StyledAsteriskText } from "../../components/StyledAsteriskTest";
import { ActionButtonDropdown } from "../../components/ActionButtonDropdown";
import { MoreHorizontal } from "react-feather";
import { LoadingIndicator } from "../../utils/openLoadingIndicator";
import { PrettyCheckbox } from "../../components/Form";
import { useOrgCurrentSeasonId } from "../../utils/useOrgCurrentSeasonId";

export default function OrgReportsTeamAccountSummary() {
  const params: any = useParams();
  const orgId = params.orgId;

  const { org, isLoading } = useOrg({ orgId });

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isLoading ? (
          <CenteredLoader />
        ) : org ? (
          <OrgReportsTeamAccountSummaryInner org={org} />
        ) : (
          <Typography>{translate({ defaultMessage: "Failed to load org" })}</Typography>
        )}
      </View>
    </Box>
  );
}

function OrgReportsTeamAccountSummaryInner(p: { org: Org }) {
  const coolerTableRef = useRef<CoolerTableMethods>(null);
  const { data: reportData, isFetched } = getBifrost().reports__server__getOrgAccountsReport.useServer(
    {
      orgId: p.org.id
    },
    { notifyOnMetaDataChanges: true }
  );
  const [headCoachNameFilter, setHeadCoachNameFilter] = useState("");
  const [teamNameFilter, setTeamNameFilter] = useState("");
  const [selectedOrgTeamTagId, setSelectedOrgTeamTagId] = useState("");
  const [selectedBirthYear, setSelectedBirthYear] = useState("");

  const orgTeamTags = useOrgTeamTags({ orgId: p.org.id });

  const allBirthYears = _.uniq(reportData?.map(a => a.birthYear).filter(a => !!a)).sort();

  return (
    <div>
      <div className="absolute inset-0">
        <LoadingIndicator isVisible={!isFetched} />
      </div>
      <div className="flex flex-row">
        <h1 className="flex-1 text-2xl sm:text-4xl mt-4 mb-4">{translate({ defaultMessage: "Team Account Summary" })}</h1>
        <ActionButtonDropdown
          style={{ marginLeft: "auto" }}
          color="secondary"
          variant="contained"
          actions={[
            {
              key: "export",
              label: () => translate.common.Export,
              onClick: async () => {
                coolerTableRef.current?.downloadCurrentDataToCSV("team-account-summary.csv");
              }
            }
          ]}
        >
          <span style={{ fontWeight: "bold", marginRight: 6 }}>{translate({ defaultMessage: "Actions" })}</span>
          <SvgIcon style={{ paddingRight: 6 }}>
            <MoreHorizontal />
          </SvgIcon>
        </ActionButtonDropdown>
      </div>
      <CoolerTable
        methodsRef={coolerTableRef}
        paginationOptions={{
          defaultPageSize: 25,
          pageSizeOptions: [25, 50, 100, 500],
          persistenceKey: "team-account-summary-report"
        }}
        items={reportData ?? []}
        columnDefs={[
          {
            label: translate.common.Team,
            getValue(item) {
              return item.name;
            },
            sortItems(items, dir) {
              return _.orderBy(items, a => a.name, dir);
            }
          },
          {
            label: translate.common.HeadCoach,
            getValue(item) {
              return item.headCoachName;
            },
            sortItems(items, dir) {
              return _.orderBy(items, a => a.headCoachName, dir);
            }
          },
          {
            label: translate.common.BirthYear,
            getValue(item) {
              return item.birthYear;
            },
            sortItems(items, dir) {
              return _.orderBy(items, a => a.birthYear, dir);
            }
          },
          {
            label: translate.common.TeamTags,
            getValue(item) {
              const thisOrgTeamTags = item.orgTeamTagIds
                ? orgTeamTags?.filter(ott => !!item.orgTeamTagIds?.[ott.id]).map(a => a.tag)
                : [];
              return _.orderBy(thisOrgTeamTags ?? [], a => a, "asc").join(", ");
            },
            sortItems(items, dir) {
              return _.orderBy(
                items,
                item => {
                  const thisOrgTeamTags = item.orgTeamTagIds
                    ? orgTeamTags?.filter(ott => !!item.orgTeamTagIds?.[ott.id]).map(a => a.tag)
                    : [];
                  return _.orderBy(thisOrgTeamTags ?? [], a => a, "asc").join(", ");
                },
                dir
              );
            }
          },
          {
            label: translate({ defaultMessage: "# Players" }),
            getValue(item) {
              return item.numPlayers;
            },
            sortItems(items, dir) {
              return _.orderBy(items, a => a.numPlayers, dir);
            },
            getValueForTotalRow(items) {
              return `${_.sum(items.map(a => a.numPlayers))}`;
            }
          },
          {
            label: translate({ defaultMessage: "# Staff" }),
            getValue(item) {
              return item.numStaff;
            },
            sortItems(items, dir) {
              return _.orderBy(items, a => a.numStaff, dir);
            },
            getValueForTotalRow(items) {
              return `${_.sum(items.map(a => a.numStaff))}`;
            }
          },
          {
            label: translate({ defaultMessage: "# Accounts" }),
            getValue(item) {
              return item.totalAccounts;
            },
            sortItems(items, dir) {
              return _.orderBy(items, a => a.totalAccounts, dir);
            },
            getValueForTotalRow(items) {
              return `${_.sum(items.map(a => a.totalAccounts))}`;
            }
          }
        ]}
        getItemKey={a => a.name}
        filters={[
          {
            filterComponent: (
              <CoolTextInput
                onChange={newVal => {
                  setTeamNameFilter(newVal ?? "");
                }}
                value={teamNameFilter}
                placeholder={translate({ defaultMessage: "Filter by Team Name" })}
              />
            ),
            onFilter(items) {
              return items.filter(item =>
                item.name.toLowerCase().replace(/\s+/g, "").match(teamNameFilter.toLowerCase().replace(/\s+/g, ""))
              );
            }
          },
          {
            filterComponent: (
              <CoolTextInput
                onChange={newVal => {
                  setHeadCoachNameFilter(newVal ?? "");
                }}
                value={headCoachNameFilter}
                placeholder={translate({ defaultMessage: "Filter by Head Coach" })}
              />
            ),
            onFilter(items) {
              return items.filter(item =>
                item.headCoachName.toLowerCase().replace(/\s+/g, "").match(headCoachNameFilter.toLowerCase().replace(/\s+/g, ""))
              );
            }
          },
          allBirthYears.length
            ? {
                filterComponent: (
                  <CoolSelectInput
                    options={allBirthYears.map(birthYear => {
                      return {
                        label: birthYear,
                        value: birthYear
                      };
                    })}
                    value={selectedBirthYear ?? ""}
                    placeholder={translate.common.BirthYear}
                    allowClear
                    onChange={newVal => {
                      setSelectedBirthYear(newVal);
                    }}
                  />
                ),
                onFilter: items => {
                  return selectedBirthYear
                    ? items.filter(item => {
                        return item.birthYear === selectedBirthYear;
                      })
                    : items;
                }
              }
            : null,
          orgTeamTags?.length
            ? {
                filterComponent: (
                  <CoolSelectInput
                    options={_.orderBy(
                      orgTeamTags.map(orgTeamTag => {
                        return {
                          label: orgTeamTag.tag,
                          value: orgTeamTag.id
                        };
                      }),
                      a => a.label.toLowerCase,
                      "asc"
                    )}
                    value={selectedOrgTeamTagId ?? ""}
                    placeholder={translate.common.TeamTag}
                    allowClear
                    onChange={newVal => {
                      setSelectedOrgTeamTagId(newVal);
                    }}
                  />
                ),
                onFilter: items => {
                  return selectedOrgTeamTagId
                    ? items.filter(item => {
                        return item.orgTeamTagIds?.[selectedOrgTeamTagId];
                      })
                    : items;
                }
              }
            : null
        ]}
      />
    </div>
  );
}
