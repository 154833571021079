import { getUniversalHelpers } from "../../helpers";
import { combineBifrostSubscriptions } from "../../internal-utils/combineBifrostSubscriptions";
import { firestoreLiftDocsSubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function org__client__getMultiOrgInfoSubscription(p: { orgIds: string[] }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return combineBifrostSubscriptions({
    orgs: firestoreLiftDocsSubToBifrostSub(h.Org.docsSubscription(p.orgIds)),
    orgSettings: firestoreLiftDocsSubToBifrostSub(h.OrgSettings.docsSubscription(p.orgIds))
  });
}
