import { Typography, useTheme, useMediaQuery, Box, Button, SvgIcon } from "@material-ui/core";
import { TouchableOpacity, View } from "react-native-web";
import { Org, OrgRegistrationStatus, OrgRegistrationTableData } from "@ollie-sports/models";
import { CenteredLoader } from "../../components/CenteredLoader";
import { dateFormatters, getCurrentLocale, translate } from "@ollie-sports/i18n";
import { Link, useParams } from "react-router-dom";
import { getBifrost } from "../../services/bifrost.service";
import {
  COLORS,
  ObjectKeys,
  PRETTY_ORG_REGISTRATION_STATUS,
  formatMoneyCentsToDollarCentPrettyString,
  getPrettyRegistrationStatus
} from "@ollie-sports/core";
import _ from "lodash";
import {
  CheckIcon,
  XMarkIcon,
  ExclamationTriangleIcon,
  LockOpenIcon,
  LinkIcon,
  PlusCircleIcon,
  ClockIcon,
  CalculatorIcon
} from "@heroicons/react/24/outline";
import { useOrg } from "../../hooks/useOrg";
import { CoolSelectInput } from "../../components/Inputs/CoolSelectInput";
import { usePersistentState } from "../../utils/usePersistentState";
import { useOrgSeasons } from "../../hooks/useOrgSeasons";
import { ShadowView } from "../../components/ShadowView";
import { StyledText } from "../../components/StyledText";
import { AsyncFancyTable, AsyncFancyTableMethods } from "../../components/AsyncFancyTable";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { CoolTextInput } from "../../components/Inputs/CoolTextInput";
import moment from "moment";
import { Clipboard, DollarSign, MoreHorizontal, User } from "react-feather";
import { getDialogOptions } from "../../components/DialogOptions";
import { InfoTooltipIcon } from "../../components/InfoTooltip";
import MailLayout, { useMailContext } from "../../components/Compose/MailLayout";
import { useEffect, useMemo, useRef, useState } from "react";
import { createPersistedSynchronousStore } from "../../utils/createPersistedSynchronousStore";
import { PersistedComposeStoreState } from "../../components/Compose/constants";
import { Money } from "@material-ui/icons";
import { ActionButtonDropdown } from "../../components/ActionButtonDropdown";
import { openSuccessToast } from "../../utils/openErrorToast";
import { isCurrentOrgSeason } from "@ollie-sports/core/dist/compute/orgSeason.compute";
import { useOrgRegistrationPackages } from "../../hooks/useOrgRegistrationPackages";
import { useOrgTeamTags } from "../../hooks/useOrgTeamTags";
import { CoolMultiSelectInput } from "../../components/Inputs/CoolMultiSelectInput";
import { useOrgCurrentSeasonId } from "../../utils/useOrgCurrentSeasonId";

export default function OrgRegistrationDashboard() {
  const params: any = useParams();
  const orgId = params.orgId;

  const { org, isLoading } = useOrg({ orgId });

  const instanceId = "registration-dashboard-" + orgId;

  const store = useMemo(() => {
    return createPersistedSynchronousStore<PersistedComposeStoreState>({
      initialState: {
        selectedRecipientGroups: [],
        subject: "",
        body: "",
        attachments: []
      },
      namespace: instanceId,
      shouldBeClearedOnUpdates: true
    });
  }, [orgId]);

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <MailLayout instanceId={instanceId} fetchContextData={false} store={store} sendFromOrgContext={org}>
        <View style={{ flex: 1 }}>
          {isLoading ? (
            <CenteredLoader />
          ) : org ? (
            <OrgRegistrationDashboardInner org={org} />
          ) : (
            <Typography>{translate({ defaultMessage: "Failed to load the dashboard" })}</Typography>
          )}
        </View>
      </MailLayout>
    </Box>
  );
}

function OrgRegistrationDashboardInner(p: { org: Org }) {
  const [selectedOrgSeasonId, setSelectedOrgSeasonId] = useOrgCurrentSeasonId(p.org.id);
  const orgTeamTags = useOrgTeamTags({ orgId: p.org.id });

  const orgSeasons = useOrgSeasons({ orgId: p.org.id }) ?? [];

  const { data: registrationStatusDetails } = getBifrost().orgRegistration__server__getOrgRegistrationDataForDashboard.useServer(
    {
      orgId: p.org.id,
      orgSeasonId: selectedOrgSeasonId
    },
    { enabled: !!selectedOrgSeasonId }
  );

  const mailContext = useMailContext();
  const { data: orgSeasonMoneyTotals } = getBifrost().orgSeason__server__getOrgSeasonMoneyTotals.useServer(
    { orgSeaonsId: selectedOrgSeasonId, orgId: p.org.id },
    { enabled: !!selectedOrgSeasonId }
  );

  const selectedOrgSeason = orgSeasons?.find(os => os.id === selectedOrgSeasonId);

  const [selectedPlayersByPlayerBundleId, setSelectedPlayersByPlayerBundleId] = usePersistentState({
    initialValue: {} as Record<string, true>,
    key: "org-dashboard-selected-players" + p.org.id
  });

  const numSelectedPlayers = Object.keys(selectedPlayersByPlayerBundleId).length;

  const asyncFancyTableRef = useRef<AsyncFancyTableMethods<OrgRegistrationTableData>>(null);

  const actionOptions = _.compact([
    {
      key: "download",
      label: () =>
        translate(
          { defaultMessage: "Export {num} Registrations to CSV" },
          { num: numSelectedPlayers || asyncFancyTableRef.current?.getCurrentNumRecords() || 0 }
        ),
      value: "",
      onClick: () => {
        asyncFancyTableRef.current?.downloadCurrentDataToCSV(
          "registrations.csv",
          numSelectedPlayers
            ? {
                filter: item => {
                  return selectedPlayersByPlayerBundleId[item.playerBundleId];
                }
              }
            : undefined
        );
      }
    },
    numSelectedPlayers
      ? {
          key: "email",
          label: () => translate({ defaultMessage: "Email Guardians of {num} Selected Players" }, { num: numSelectedPlayers }),
          onClick: async () => {
            mailContext.openMailComposer({
              resetWithSelectedRecipients: [
                {
                  type: "arbitraryPlayerBundleGrouping",
                  playerBundleIds: Object.keys(selectedPlayersByPlayerBundleId),
                  lookupKey: Object.keys(selectedPlayersByPlayerBundleId).join(",")
                }
              ]
            });
          }
        }
      : null,
    mailContext.hasSelectedRecipients
      ? {
          key: "open",
          label: () => translate({ defaultMessage: "Open Saved Email Draft" }),
          onClick: async () => {
            mailContext.openMailComposer();
          }
        }
      : null
  ]);

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: "row" }}>
        <h1 className="text-2xl sm:text-4xl flex-1 mt-4">{translate({ defaultMessage: "Registrations" })}</h1>
      </View>
      <div className="inline-block">
        <CoolSelectInput
          label={translate.common.Season}
          containerStyle={{ marginTop: 30 }}
          placeholder={translate({ defaultMessage: "Select Season" })}
          value={selectedOrgSeasonId}
          inputProps={{
            style: { fontSize: 20, height: 60, minWidth: 280, width: "auto" }
          }}
          options={orgSeasons
            .filter(os => !os.archivedAtMS)
            .map(os => {
              return {
                label: os.name,
                value: os.id
              };
            })}
          onChange={newVal => {
            if (numSelectedPlayers) {
              const yes = window.confirm(
                translate({
                  defaultMessage: "You have selected players. Do you wish to clear your selection and change seasons?"
                })
              );
              if (!yes) {
                return;
              }
              setSelectedPlayersByPlayerBundleId({});
            }
            setSelectedOrgSeasonId(newVal);
          }}
        />
      </div>
      {selectedOrgSeasonId ? (
        <>
          <ShadowView style={{ padding: 24, marginTop: 16, borderRadius: 16 }}>
            <View style={{ flexDirection: "row" }}>
              <StatusItem
                {...getIconAndLabelForOrgRegistrationStatus(OrgRegistrationStatus.registered)}
                value={`${registrationStatusDetails?.registered ?? 0}`}
                numItemsInSection={5}
              />
              <StatusItem
                {...getIconAndLabelForOrgRegistrationStatus(OrgRegistrationStatus.incomplete)}
                value={`${registrationStatusDetails?.incomplete ?? 0}`}
                numItemsInSection={5}
              />
              <StatusItem
                {...getIconAndLabelForOrgRegistrationStatus(OrgRegistrationStatus["no-registration-needed"])}
                value={`${registrationStatusDetails?.noRegistrationNeeded ?? 0}`}
                numItemsInSection={5}
              />
              <StatusItem
                {...getIconAndLabelForOrgRegistrationStatus(OrgRegistrationStatus.unregistered)}
                value={`${registrationStatusDetails?.unregistered ?? 0}`}
                numItemsInSection={5}
              />
              <StatusItem
                {...getIconAndLabelForOrgRegistrationStatus(OrgRegistrationStatus.bad)}
                value={`${registrationStatusDetails?.bad ?? 0}`}
                numItemsInSection={5}
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                marginHorizontal: 20,
                marginTop: 30,
                paddingTop: 30,
                borderTopWidth: 1,
                borderColor: "rgba(0,0,0,0.15)"
              }}
            >
              <StatusItem
                label={translate({ defaultMessage: "Paid Amount" })}
                toolTip={translate({
                  defaultMessage: "Total of all registration payments that have been received to date, including late fees."
                })}
                value={formatMoneyCentsToDollarCentPrettyString(
                  (orgSeasonMoneyTotals?.defaultAmountPaidCents ?? 0) + (orgSeasonMoneyTotals?.lateFeeAmountPaidCents ?? 0)
                )}
                icon={<DollarSign color={COLORS.grey_45} size={30} style={{ position: "relative", bottom: -10 }} />}
                numItemsInSection={3}
              />
              <StatusItem
                label={translate({ defaultMessage: "Scheduled Amount" })}
                toolTip={translate({ defaultMessage: "Total of all scheduled registration payment plan installments." })}
                value={formatMoneyCentsToDollarCentPrettyString(orgSeasonMoneyTotals?.amountScheduledCents ?? 0)}
                icon={<ClockIcon color={COLORS.grey_45} />}
                numItemsInSection={3}
              />
              <StatusItem
                label={translate({ defaultMessage: "Estimated Amount" })}
                toolTip={translate({
                  defaultMessage:
                    "Total expected paments if every player registers. This number may decrease as credits or coupons are applied."
                })}
                value={formatMoneyCentsToDollarCentPrettyString(orgSeasonMoneyTotals?.amountExpectedTotalCents ?? 0)}
                icon={<CalculatorIcon color={COLORS.grey_45} />}
                numItemsInSection={3}
              />
            </View>
          </ShadowView>
          <div className="mt-8 mb-16 pb-8">
            <AsyncFancyTable
              methodsRef={asyncFancyTableRef}
              getRowOptions={(a: OrgRegistrationTableData) => ({})} //This line here to hint typescript...
              selectRowOptions={{
                selectedItemsByKey: selectedPlayersByPlayerBundleId,
                onUpdate: items => {
                  setSelectedPlayersByPlayerBundleId(items);
                },
                selectAllQuestionText: currData =>
                  translate(
                    {
                      defaultMessage:
                        "Select all {num} players? To select only the players on this page, select the first row, hold down the shift key, and then click the last row."
                    },
                    { num: currData.totalNumberOfItemsMatchingCriteria }
                  )
              }}
              getRowKey={it => it.playerBundleId} //This HAS to stay as the player bundle id. Do NOT change.
              pagination={{ initialPageSize: 25, pageSizes: [25, 50, 100, 150] }}
              dataCachingKey={"registration-dashboard-table-" + p.org.id}
              fetchItems={info => {
                return getBifrost()
                  .orgRegistration__server__getRegistrationDataForDashboardTable.fetchServer({
                    orgId: p.org.id,
                    orgSeasonId: selectedOrgSeasonId,
                    searchTerm: info.filters?.search,
                    status: info.filters?.status as OrgRegistrationStatus | undefined,
                    selfAccountId: getCurrentUserAccountId(),
                    offset: info.pagination.numItemsPerPage * (info.pagination.page - 1),
                    sort: info.sort as any,
                    limit: info.pagination.numItemsPerPage,
                    orgTeamTagIds: info.filters?.orgTeamTagIds
                  })
                  .then(a => {
                    return {
                      itemsToBeRendered: a.data.data,
                      totalNumberOfItemsMatchingCriteria: a.data.count
                    };
                  });
              }}
              defaultSort={[{ key: "name", dir: "asc" }]}
              extraDeps={[selectedOrgSeasonId, p.org.id]}
              columns={{
                name: {
                  label: translate({ defaultMessage: "Player Name" }),
                  getCell(item: OrgRegistrationTableData) {
                    return `${item.firstName} ${item.lastName}`;
                  },
                  sortable: true
                },
                packageName: {
                  label: translate.common.RegistrationPackage,
                  getCell(item: OrgRegistrationTableData) {
                    return item.packageName || "";
                  },
                  sortable: true
                },
                packageAmountCents: {
                  label: translate({ defaultMessage: "Registration Amount" }),
                  getCell(item) {
                    return item.orgRegistration?.relevantPackageInfoSnapshot?.amountCents
                      ? formatMoneyCentsToDollarCentPrettyString(item.orgRegistration.relevantPackageInfoSnapshot.amountCents)
                      : item.packageAmountCents
                      ? formatMoneyCentsToDollarCentPrettyString(item.packageAmountCents)
                      : "";
                  },
                  sortable: true
                },
                status: {
                  label: translate.common.Status,
                  getCell(item: OrgRegistrationTableData) {
                    const isSelectedSeasonLive = selectedOrgSeason ? isCurrentOrgSeason(selectedOrgSeason) : false;
                    return !isSelectedSeasonLive
                      ? translate({ defaultMessage: "Season Not Started" })
                      : PRETTY_ORG_REGISTRATION_STATUS(getCurrentLocale())[item.status];
                  },
                  sortable: true
                },
                registrationDateMS: {
                  label: translate({ defaultMessage: "Date Registered" }),
                  getCell(item: OrgRegistrationTableData) {
                    return item.registrationDateMS
                      ? dateFormatters.mm_dd_yyyy(moment(item.registrationDateMS).toDate(), getCurrentLocale())
                      : "";
                  },
                  sortable: true
                }
              }}
              buttons={[
                {
                  icon: () => (
                    <div className="relative">
                      <DollarSign color={COLORS.blue} style={{ width: 8, height: 8, position: "absolute", left: 8, top: 7 }} />
                      <Clipboard color={COLORS.blue} style={{ width: 24, height: 20 }} />
                    </div>
                  ),
                  isVisible: item => !!item.orgRegistration,
                  getHref: (item: OrgRegistrationTableData) =>
                    `/app/org/${p.org.id}/registrations/${item.orgRegistration?.orgInvoiceId}`
                },
                {
                  icon: () => <User color={COLORS.blue} style={{ width: 24, height: 20 }} />,
                  getHref: item =>
                    `/app/org/${p.org.id}/registrations/members/${item.selfAthleteAccountId ?? item.playerBundleId}`
                },
                {
                  icon: () => <LinkIcon color={COLORS.blue} style={{ width: 24, height: 20 }} />,
                  onClick: async item => {
                    navigator.clipboard.writeText(
                      `${window.location.origin}/org/${p.org.id}/register/${item.playerBundleId}/${selectedOrgSeasonId}`
                    );
                    openSuccessToast(
                      translate(
                        { defaultMessage: "Copied {playerName}'s Registration Link" },
                        { playerName: item.firstName.trim() }
                      )
                    );
                  },
                  isVisible: item =>
                    item.status === OrgRegistrationStatus.unregistered || item.status === OrgRegistrationStatus.incomplete
                }
              ]}
              renderFiltersWrapper={filters => <div className="my-4 flex flex-row items-center">{filters}</div>}
              renderFilters={{
                search: a => (
                  <CoolTextInput
                    style={{ width: 500 }}
                    value={a.valueImmediate}
                    isLoading={a.value !== a.valueImmediate || a.isTableFetching}
                    placeholder={translate({ defaultMessage: "Search by player name" })}
                    onChange={newVal => {
                      if (newVal) {
                        a.setValueDebounced(newVal, 500, { leading: false, trailing: true });
                      } else {
                        a.setValue("");
                      }
                    }}
                  />
                ),
                orgTeamTagIds: a =>
                  orgTeamTags?.length ? (
                    <CoolMultiSelectInput
                      options={orgTeamTags.map(orgTeamTag => {
                        return {
                          label: orgTeamTag.tag,
                          value: orgTeamTag.id
                        };
                      })}
                      value={_.compact(
                        Object.keys(a.valueImmediate ?? {}).map(a => {
                          const orgTeamTag = orgTeamTags?.find(ott => ott.id === a);
                          if (orgTeamTag) {
                            return {
                              label: orgTeamTag.tag,
                              value: orgTeamTag.id
                            };
                          } else {
                            return null;
                          }
                        })
                      )}
                      containerStyle={{ marginLeft: 16 }}
                      placeholder={translate.common.TeamTag}
                      onChange={newVal => {
                        a.setValue(
                          newVal.reduce((acc, val) => {
                            acc[val.value] = true;
                            return acc;
                          }, {} as Record<string, true>)
                        );
                      }}
                    />
                  ) : null,
                status: a => (
                  <>
                    <CoolSelectInput
                      containerStyle={{ marginLeft: 16 }}
                      value={a.valueImmediate}
                      onChange={newVal => {
                        a.setValue(newVal);
                      }}
                      placeholder={translate.common.Status}
                      allowClear
                      options={ObjectKeys(OrgRegistrationStatus).map(status => {
                        return {
                          value: status,
                          label: PRETTY_ORG_REGISTRATION_STATUS(getCurrentLocale())[status]
                        };
                      })}
                    />
                    <ActionButtonDropdown
                      disabled={actionOptions.length === 0}
                      style={{ marginLeft: "auto" }}
                      actions={actionOptions}
                    >
                      <span style={{ fontWeight: "normal", marginRight: 6 }}>
                        {translate({ defaultMessage: "Actions" }, { num: numSelectedPlayers })}
                      </span>
                      <SvgIcon style={{ paddingRight: 6 }}>
                        <MoreHorizontal />
                      </SvgIcon>
                    </ActionButtonDropdown>
                  </>
                )
              }}
              initialFilterValues={{
                search: "",
                status: "",
                orgTeamTagIds: {}
              }}
            />
          </div>
        </>
      ) : null}
    </View>
  );
}

function StatusItem(p: { icon: JSX.Element; label: string; value: string; numItemsInSection: number; toolTip?: string }) {
  return (
    <View style={{ flexDirection: "column", width: `${100 / p.numItemsInSection}%`, alignItems: "center", paddingHorizontal: 8 }}>
      <View style={{ height: 40, width: 40, marginBottom: 16 }}>{p.icon}</View>
      <View style={{ flexDirection: "row", flex: 1, alignItems: "center" }}>
        <div>
          {p.label.split("\n").map((a, i) => {
            return <StyledText key={i}>{a}</StyledText>;
          })}
        </div>
        {p.toolTip ? <InfoTooltipIcon title={p.toolTip} iconClassName="h-4 w-4" /> : null}
      </View>
      <StyledText style={{ fontSize: 24, marginTop: 16 }}>{p.value}</StyledText>
    </View>
  );
}

function getIconAndLabelForOrgRegistrationStatus(status: OrgRegistrationStatus) {
  const icon =
    status === "registered" ? (
      <CheckIcon color={COLORS.green} />
    ) : status === "incomplete" ? (
      <ExclamationTriangleIcon color={COLORS.yellow} />
    ) : status === "unregistered" ? (
      <XMarkIcon color={COLORS.red} />
    ) : status === "no-registration-needed" ? (
      <LockOpenIcon color={COLORS.grey_45} />
    ) : (
      <ExclamationTriangleIcon color={COLORS.red} />
    );
  const label =
    status === "registered"
      ? translate({ defaultMessage: "Registered" })
      : status === "unregistered"
      ? translate({ defaultMessage: "Unregistered" })
      : status === "incomplete"
      ? translate({ defaultMessage: "In Progress" })
      : status === "no-registration-needed"
      ? translate({ defaultMessage: "No Registration Required" })
      : translate({ defaultMessage: "Past Due/Problems" });
  return { icon, label };
}
