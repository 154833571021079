import { Typography, Box, Button, SvgIcon } from "@material-ui/core";
import { View } from "react-native-web";
import {
  Org,
  OrgInvoiceTypes,
  PlayerBundleId,
  OrgPaymentType,
  OrgPayment,
  OrgInvoice,
  OrgInvoiceParent,
  PaymentMethodType,
  OrgPaymentRefund
} from "@ollie-sports/models";
import { CenteredLoader } from "../../components/CenteredLoader";
import { dateFormatters, getCurrentLocale, translate } from "@ollie-sports/i18n";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { GlobeAltIcon, LinkIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { useOrg } from "../../hooks/useOrg";
import {
  COLORS,
  PRETTY_PAYMENT_ERROR_CODES,
  PRETTY__ORG_PAYMENT_TYPE,
  formatMoneyCentsToDollarCentPrettyString,
  getOverallOrgInvoiceAmountDetails
} from "@ollie-sports/core";
import { getBifrost } from "../../services/bifrost.service";
import moment from "moment";
import { CoolTextInput } from "../../components/Inputs/CoolTextInput";
import { useRef, useState } from "react";
import { AsyncFancyTable, AsyncFancyTableMethods } from "../../components/AsyncFancyTable";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { CoolSelectInput } from "../../components/Inputs/CoolSelectInput";
import { CoolDateInput } from "../../components/Inputs/CoolDateInput";
import { MoreHorizontal, Clipboard, DollarSign } from "react-feather";
import { ActionButtonDropdown } from "../../components/ActionButtonDropdown";
import { CoolMultiSelectInput } from "../../components/Inputs/CoolMultiSelectInput";

export default function OrgRefunds() {
  const params: any = useParams();
  const orgId = params.orgId;

  const { org, isLoading } = useOrg({ orgId });

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isLoading ? (
          <CenteredLoader />
        ) : org ? (
          <OrgRefundsInner org={org} />
        ) : (
          <Typography>{translate({ defaultMessage: "Failed to load refunds" })}</Typography>
        )}
      </View>
    </Box>
  );
}

type RowType = {
  orgPaymentRefund: OrgPaymentRefund;
  orgPayment: OrgPayment;
  orgInvoice: OrgInvoice;
  parentOrgInvoice: OrgInvoiceParent | undefined;
  playerName: string;
  teamShortName: string;
  teamName: string;
};

function OrgRefundsInner(p: { org: Org }) {
  const asyncFancyTableRef = useRef<AsyncFancyTableMethods<RowType>>(null);
  return (
    <div style={{ flex: 1 }}>
      <div className="flex">
        <h1 className="flex-1 text-2xl sm:text-4xl mt-4">{translate.common.Refunds}</h1>
        <ActionButtonDropdown
          style={{ marginLeft: "auto" }}
          color="secondary"
          actions={[
            {
              key: "csv-export",
              label: () =>
                translate(
                  { defaultMessage: "Export {num} Records to CSV" },
                  { num: asyncFancyTableRef.current?.getCurrentNumRecords() || 0 }
                ),
              onClick: () => {
                asyncFancyTableRef.current?.downloadCurrentDataToCSV("refunds.csv");
              }
            }
          ]}
        >
          <span style={{ fontWeight: "bold", marginRight: 6 }}>{translate({ defaultMessage: "Actions" })}</span>
          <SvgIcon style={{ paddingRight: 6 }}>
            <MoreHorizontal />
          </SvgIcon>
        </ActionButtonDropdown>
      </div>
      <div className="pb-8">
        <AsyncFancyTable
          methodsRef={asyncFancyTableRef}
          getRowKey={item => item.orgPaymentRefund.id}
          pagination={{ initialPageSize: 50, pageSizes: [50, 100, 150] }}
          dataCachingKey={"payments-" + p.org.id}
          noItemsMessage={translate({ defaultMessage: "No payments created yet..." })}
          noFilteredItemsMessage={translate({ defaultMessage: "No payments matching selected filters..." })}
          fetchItems={info =>
            getBifrost()
              .orgPaymentRefund__server__getOrgPaymentRefundsForOrg.fetchServer({
                orgId: p.org.id,
                searchTerm: info.filters?.search,
                selfAccountId: getCurrentUserAccountId(),
                offset: info.pagination.numItemsPerPage * (info.pagination.page - 1),
                sort: info.sort,
                limit: info.pagination.numItemsPerPage,
                startDate: info.filters?.startDate,
                endDate: info.filters?.endDate,
                category: info.filters?.category as "registration" | "invoice" | undefined
              })
              .then(a => {
                console.log("+++++++++++++=", "uwaO1bn6jq");
                console.log(a.data.count, "09OwoNqUdh");
                console.log(a.data.data.length, "09OwoNqUdh");
                return {
                  itemsToBeRendered: a.data.data,
                  totalNumberOfItemsMatchingCriteria: a.data.count
                };
              })
          }
          getRowOptions={(item: RowType) => {
            return {
              href: `/app/org/${item.orgInvoice.orgId}/invoices/${item.parentOrgInvoice?.id ?? item.orgInvoice.id}`
            };
          }}
          extraDeps={[p.org.id]}
          columns={{
            name: {
              label: translate.common.Player,
              getCell(item) {
                return item.playerName;
              },
              sortable: true
            },
            payment_method: {
              label: translate({ defaultMessage: "Payment Method" }),
              getCell(item) {
                if ("paymentMethodSnapshot" in item.orgPayment) {
                  return item.orgPayment.paymentMethodSnapshot!.type === PaymentMethodType.bank
                    ? translate(
                        { defaultMessage: "Bank account ending in {lastFour}" },
                        { lastFour: item.orgPayment.paymentMethodSnapshot?.accountNumberLast4 || "" }
                      )
                    : translate(
                        { defaultMessage: "Credit card ending in {lastFour}" },
                        { lastFour: item.orgPayment.paymentMethodSnapshot?.cardNumberLast4 || "" }
                      );
                } else {
                  return "";
                }
              },
              exportOnlyColumn: true
            },
            category: {
              label: translate({ defaultMessage: "Category" }),
              getCell(item) {
                return item.orgInvoice.type === OrgInvoiceTypes.registration ||
                  item.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
                  ? translate.common.Registration
                  : translate.common.Invoice;
              },
              sortable: true
            },
            team: {
              label: translate.common.Team,
              getCell: item => item.teamShortName,
              sortable: false
            },
            teamLongName: {
              label: translate({ defaultMessage: "Full Team Name" }),
              getCell: item => item.teamName,
              exportOnlyColumn: true
            },
            amount: {
              label: translate.common.Amount,
              getCell(item) {
                return formatMoneyCentsToDollarCentPrettyString(item.orgPaymentRefund?.totalAmountRefundedCents ?? 0);
              },
              sortable: true
            },
            date: {
              label: translate.common.Date,
              getCell(item: RowType) {
                return dateFormatters.mm_dd_yyyy(moment(item.orgPayment.createdAtMS).toDate(), getCurrentLocale());
              },
              sortable: true
            },
            deposit_date_guess: {
              label: translate({ defaultMessage: "Deposit Date Guess" }),
              getCell(item) {
                if (item.orgPayment.type === OrgPaymentType.invoiceDefault && item.orgPayment.paymentMethodSnapshot?.type) {
                  if (item.orgPayment.paymentMethodSnapshot.type == PaymentMethodType.bank) {
                    return dateFormatters.mm_dd_yyyy(getNextNthBusinessDay(item.orgPayment.createdAtMS, 5));
                  } else {
                    return dateFormatters.mm_dd_yyyy(getNextNthBusinessDay(item.orgPayment.createdAtMS, 1));
                  }
                } else {
                  return "";
                }
              },
              exportOnlyColumn: true
            },
            note: {
              label: translate.common.Note,
              getCell(item: RowType) {
                return item.orgPaymentRefund?.note ?? "";
              }
            }
          }}
          buttons={[
            {
              icon: () => (
                <div className="relative">
                  <DollarSign color={COLORS.blue} style={{ width: 8, height: 8, position: "absolute", left: 8, top: 7 }} />
                  <Clipboard color={COLORS.blue} style={{ width: 24, height: 20 }} />
                </div>
              ),
              isVisible: item => !!item.parentOrgInvoice,
              getHref: (item: RowType) => `/app/org/${p.org.id}/registrations/${item.parentOrgInvoice?.id}`
            }
          ]}
          renderFiltersWrapper={filters => <div className="my-4 flex flex-row flex-wrap">{filters}</div>}
          renderFilters={{
            search: a => (
              <CoolTextInput
                style={{ width: 500 }}
                value={a.valueImmediate}
                isLoading={a.value !== a.valueImmediate || a.isTableFetching}
                placeholder={translate({ defaultMessage: "Search by player name" })}
                onChange={newVal => {
                  if (newVal) {
                    a.setValueDebounced(newVal, 500, { leading: false, trailing: true });
                  } else {
                    a.setValue("");
                  }
                }}
              />
            ),
            category: a => (
              <CoolSelectInput
                value={a.value}
                placeholder={translate({ defaultMessage: "Category" })}
                onChange={newVal => {
                  a.setValue(newVal || "");
                }}
                containerStyle={{ marginLeft: 8 }}
                allowClear
                options={[
                  { value: "invoice", label: translate.common.Invoice },
                  { value: "registration", label: translate.common.Registration }
                ]}
              />
            ),
            startDate: a => (
              <CoolDateInput
                className="ml-1"
                value={a.value ? moment(a.value).toDate() : undefined}
                placeholderText={translate.common.StartDate}
                isClearable
                onChange={newVal => {
                  a.setValue(moment(newVal).startOf("day").valueOf());
                }}
              />
            ),
            endDate: a => (
              <CoolDateInput
                className="ml-1.5"
                value={a.value ? moment(a.value).toDate() : undefined}
                placeholderText={translate.common.EndDate}
                isClearable
                onChange={newVal => {
                  a.setValue(moment(newVal).endOf("day").valueOf());
                }}
              />
            )
          }}
          initialFilterValues={{
            search: "",
            category: "",
            startDate: undefined as number | undefined,
            endDate: undefined as number | undefined
          }}
        />
      </div>
    </div>
  );
}

function PlayerNameCell(p: { playerBundleId: PlayerBundleId }) {
  const { data: playerBundle } = getBifrost().playerBundle__server__getPlayerBundle.useServer({
    id: p.playerBundleId
  });
  return (
    <div>
      {playerBundle ? `${playerBundle.virtualAthleteAccount.firstName} ${playerBundle.virtualAthleteAccount.lastName}` : ""}
    </div>
  );
}

function getNextNthBusinessDay(date, n) {
  let businessDayCount = 0;
  let nextDay = moment(date);

  // Continue adding days until n business days are counted
  while (businessDayCount < n) {
    nextDay.add(1, "days");

    // Only count weekdays (Monday to Friday)
    if (nextDay.isoWeekday() <= 5) {
      businessDayCount++;
    }
  }

  return nextDay;
}
