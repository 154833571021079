import { useTheme, useMediaQuery } from "@material-ui/core";
import { View } from "react-native-web";
import _ from "lodash";
import { Org, OrgTeamTag, TeamId } from "@ollie-sports/models";
import { translate } from "@ollie-sports/i18n";
import CoolerTable from "../../components/CoolerTable";
import { CoolSelectInput } from "../../components/Inputs/CoolSelectInput";
import { useState } from "react";
import { useOrgTeams } from "../../hooks/useOrgTeams";
import { CoolTextInput, CoolTextInputWithLabel } from "../../components/Inputs/CoolTextInput";
import { useImmutableState } from "../../utils/useImmutableState";
import { ObjectKeys } from "@ollie-sports/core";
import { StyledButton } from "../../components/StyledButton";
import { getBifrost } from "../../services/bifrost.service";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { useOrgTeamTags } from "../../hooks/useOrgTeamTags";
import { FullScreenModal } from "../../components/modals/getFullscreenModal";
import { openModal } from "../../components/modals/imperativeModal";
import { prettyGender } from "../../utils/genderUtils";
import { Form, PrettyCoolTextInputWithLabel } from "../../components/Form";
import { dequal } from "dequal";

type Props = {
  org: Org;
  type: "add" | "edit";
  orgTeamTag?: OrgTeamTag;
};
export function openOrgTeamTagAddEditModal(p: Props) {
  const modal = openModal({
    body: (
      <OrgTeamTagAddEditModal
        {...p}
        onRequestDismiss={() => {
          modal.close();
        }}
      />
    )
  });

  return modal;
}

export default function OrgTeamTagAddEditModal(p: Props & { onRequestDismiss: () => void }) {
  const orgTeams =
    useOrgTeams({
      orgId: p.org.id
    }) || [];
  const orgTeamTags = useOrgTeamTags({
    orgId: p.org.id
  });

  const orgTeamTagsObj =
    orgTeamTags?.reduce((acc, a) => {
      acc[a.id] = a;
      return acc;
    }, {} as Record<string, OrgTeamTag>) || {};

  const [searchInput, setSearchInput] = useState("");
  const initialSelectedOrgTeamIds = orgTeams
    .filter(t => p.orgTeamTag && !!t.assignedOrgTagIds?.[p.orgTeamTag.id])
    .reduce((acc, t) => {
      acc[t.id] = true;
      return acc;
    }, {} as Record<TeamId, true>);

  const [selectedOrgTeamIds, setSelectedOrgTeamIds] = useImmutableState<Record<TeamId, true>>(initialSelectedOrgTeamIds);

  const [selectedStatusFilter, setSelectedStatusFilter] = useState<"active" | "inactive" | undefined>(undefined);
  const [orgTeamTagInput, setOrgTeamTagInput] = useState(p.orgTeamTag?.tag ?? "");

  async function onSave() {
    if (!p.orgTeamTag) {
      // Creating Tag
      if (orgTeamTagInput) {
        const { data: newTag } = await getBifrost().orgTeamTag__server__createTag.fetchServer({
          orgId: p.org.id,
          selfAccountId: getCurrentUserAccountId(),
          tag: orgTeamTagInput
        });
        await getBifrost().orgTeamTag__server__bulkUpdateTag.fetchServer({
          orgId: p.org.id,
          orgTeamTagId: newTag.id,
          selfAccountId: getCurrentUserAccountId(),
          teamIdsToAdd: orgTeams.filter(t => !!selectedOrgTeamIds[t.id]).map(t => t.id),
          teamIdsToRemove: []
        });
      } else {
        // No tag entered, show error
      }
    } else {
      // Editing Tag
      const didTagChange = p.orgTeamTag.tag !== orgTeamTagInput;

      let teamIdsToAdd: TeamId[] = [];
      let teamIdsToRemove: TeamId[] = [];

      // Add the tag to all selected teams that weren't previously selected
      teamIdsToAdd = (orgTeams ?? [])
        .filter(team => p.orgTeamTag && !team.assignedOrgTagIds?.[p.orgTeamTag.id] && !!selectedOrgTeamIds[team.id])
        .map(team => team.id);
      // Remove the tag from any team that previously had that tag and now is not selected
      teamIdsToRemove = (orgTeams ?? [])
        .filter(team => p.orgTeamTag && !!team.assignedOrgTagIds?.[p.orgTeamTag.id] && !selectedOrgTeamIds[team.id])
        .map(team => team.id);

      try {
        await getBifrost().orgTeamTag__server__bulkUpdateTag.fetchServer({
          orgId: p.orgTeamTag.orgId,
          orgTeamTagId: p.orgTeamTag.id,
          selfAccountId: getCurrentUserAccountId(),
          teamIdsToAdd,
          teamIdsToRemove,
          newTag: didTagChange ? orgTeamTagInput : undefined
        });
      } catch (e) {}
    }
    p.onRequestDismiss();
  }

  const numSelectedTeams = Object.keys(selectedOrgTeamIds).length;

  return (
    <Form>
      {isFormValid => (
        <FullScreenModal
          containerStyle={{ maxWidth: 800 }}
          title={p.type === "add" ? translate.common.CreateTeamTag : translate({ defaultMessage: "Edit Team Tag" })}
          bottomButton={{
            title:
              (p.type === "add" ? translate.common.Create : translate.common.Save) +
              (numSelectedTeams ? ` (${translate({ defaultMessage: "{num} selected" }, { num: numSelectedTeams })})` : ""),
            onPress: onSave,
            enabled: isFormValid
          }}
          onRequestDismiss={() => {
            if (
              (!dequal(initialSelectedOrgTeamIds, selectedOrgTeamIds) || orgTeamTagInput !== (p.orgTeamTag?.tag || "")) &&
              !window.confirm(translate({ defaultMessage: "You have unsaved changes. Are you sure you wish to leave?" }))
            ) {
              return;
            }

            p.onRequestDismiss();
          }}
        >
          <PrettyCoolTextInputWithLabel
            onChange={newVal => {
              setOrgTeamTagInput(newVal ?? "");
            }}
            isRequired
            label={translate({ defaultMessage: "Tag Name" })}
            value={orgTeamTagInput}
          />
          <div className="mt-7 mb-1">
            <label className=" text-sm font-extrabold">
              {translate({ defaultMessage: "Selected Teams" })} (
              {ObjectKeys(selectedOrgTeamIds).filter(teamId => !!selectedOrgTeamIds[teamId]).length}/{orgTeams.length})
            </label>
          </div>

          <CoolerTable
            items={orgTeams}
            defaultSortSettings={{
              label: translate({ defaultMessage: "Team Name" }),
              dir: "asc"
            }}
            onRowClick={item => {
              setSelectedOrgTeamIds(s => {
                if (s[item.id]) {
                  delete s[item.id];
                } else {
                  s[item.id] = true;
                }
              });
            }}
            // getItemSelected={item => {
            //   return selectedOrgTeamIds[item.id];
            // }}
            // selectAllButtonOptions={{
            //   getText: items => {
            //     const shouldSelect = items.find(item => !selectedOrgTeamIds[item.id]);
            //     const numItems = items.filter(a => (shouldSelect ? !selectedOrgTeamIds[a.id] : selectedOrgTeamIds[a.id])).length;
            //     return shouldSelect
            //       ? translate({ defaultMessage: "Select All {num} Team(s)" }, { num: numItems })
            //       : translate({ defaultMessage: "Deselect All {num} Team(s)" }, { num: numItems });
            //   },

            //   onClick: items => {
            //     const shouldSelect = items.find(item => !selectedOrgTeamIds[item.id]);
            //     setSelectedOrgTeamIds(s => {
            //       items.forEach(t => {
            //         if (shouldSelect) {
            //           s[t.id] = true;
            //         } else {
            //           delete s[t.id];
            //         }
            //       });
            //     });
            //   }
            // }}
            filters={[
              {
                filterComponent: (
                  <CoolTextInput
                    value={searchInput}
                    onChange={newVal => {
                      setSearchInput(newVal ?? "");
                    }}
                    showClearButton
                    inputProps={{
                      placeholder: translate({
                        defaultMessage: "Filter teams...",
                        description: "Telling the user to filter a list by the team name"
                      })
                    }}
                  />
                ),
                onFilter: items => {
                  return searchInput
                    ? items.filter(item => {
                        const haystackArr = [
                          item.name,
                          item.gender ? prettyGender(item.gender) : "",
                          item.birthYear || "",
                          ...Object.keys(item.assignedOrgTagIds || {}).map(a => orgTeamTagsObj[a]?.tag)
                        ].filter(Boolean);

                        const haystack = haystackArr.join(" ");

                        return searchInput
                          .split(/ +/)
                          .map(a => a.trim())
                          .filter(Boolean)
                          .every(needle => haystack.toLowerCase().includes(needle.toLowerCase()));
                      })
                    : items;
                }
              },
              {
                filterComponent: (
                  <CoolSelectInput
                    value={selectedStatusFilter ?? ""}
                    onChange={newVal => {
                      if (newVal === "active" || newVal === "inactive") {
                        setSelectedStatusFilter(newVal);
                      } else {
                        setSelectedStatusFilter(undefined);
                      }
                    }}
                    options={[
                      { label: translate({ defaultMessage: "Selected" }), value: "active" },
                      { label: translate({ defaultMessage: "Unselected" }), value: "inactive" }
                    ]}
                    placeholder={translate.common.Status}
                    allowClear
                  />
                ),
                onFilter(items) {
                  if (selectedStatusFilter === "active") {
                    return items.filter(item => selectedOrgTeamIds[item.id]);
                  }
                  if (selectedStatusFilter === "inactive") {
                    return items.filter(item => !selectedOrgTeamIds[item.id]);
                  }
                  return items;
                }
              }
            ]}
            columnDefs={[
              {
                label: translate({ defaultMessage: "Team Name" }),
                getValue(item) {
                  return item.name;
                },
                sortItems(items, dir) {
                  return _.orderBy(items, a => a.name.toLowerCase(), dir);
                }
              },
              {
                label: translate.common.Gender,
                getValue(item) {
                  return prettyGender(item.gender);
                },
                sortItems(items, dir) {
                  return _.orderBy(items, a => prettyGender(a.gender), dir);
                }
              },
              {
                label: translate.common.BirthYear,
                getValue(item) {
                  return item.birthYear;
                },
                sortItems(items, dir) {
                  return _.orderBy(items, a => a.birthYear?.toLowerCase(), dir);
                }
              },
              {
                label: translate({ defaultMessage: "Current Tags" }),
                getValue(item) {
                  const tags = Object.keys(item.assignedOrgTagIds || {})
                    .map(tagid => orgTeamTagsObj[tagid])
                    .map(a => (
                      <div className="px-2 py-1 rounded-xl text-white bg-blue-500" key={a.id}>
                        {a.tag}
                      </div>
                    ));
                  return (
                    <div style={{ maxWidth: 100 }} className="flex flex-wrap gap-2">
                      {tags}
                    </div>
                  );
                }
              },
              {
                label: translate({ defaultMessage: "Selected" }),
                getValue: item => {
                  return (
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5 text-blue-500"
                      checked={!!selectedOrgTeamIds[item.id]}
                    />
                  );
                }
              }
            ]}
            getItemKey={item => item.id}
          />
        </FullScreenModal>
      )}
    </Form>
  );
}
