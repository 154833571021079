import { Typography, Box, Button, SvgIcon, Menu } from "@material-ui/core";
import { View } from "react-native-web";
import {
  ORG_PERMISSIONS,
  Org,
  OrgInvoiceTypes,
  OrgSecret,
  OrgSettings,
  OverallInvoiceStatus,
  ParentInvoiceData
} from "@ollie-sports/models";
import { CenteredLoader } from "../../components/CenteredLoader";
import { dateFormatters, getCurrentLocale, translate } from "@ollie-sports/i18n";
import { useParams } from "react-router-dom";
import { ArrowPathIcon, PencilIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useAllOrgInfo } from "../../hooks/useOrg";
import { openOrgInvoiceAddEditModal } from "./OrgInvoiceAddEdit";
import {
  COLORS,
  ObjectKeys,
  PRETTY_OVERALL_ORG_INVOICE_STATUS,
  compute,
  formatMoneyCentsToDollarCentPrettyString
} from "@ollie-sports/core";
import { getBifrost } from "../../services/bifrost.service";
import moment from "moment";
import { CoolTextInput } from "../../components/Inputs/CoolTextInput";
import { useRef, useState } from "react";
import { AsyncFancyTable, AsyncFancyTableMethods } from "../../components/AsyncFancyTable";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import getConfirm from "../../components/modals/getConfirm";
import { CoolDateInput } from "../../components/Inputs/CoolDateInput";
import { twMerge } from "tailwind-merge";
import { CoolMultiSelectInput } from "../../components/Inputs/CoolMultiSelectInput";
import { wrapPromiseWithLoader } from "../../utils/wrapPromiseWithLoader";
import { openPaymentForgivenessModal } from "../../utils/openPaymentForgivenessModal";
import { openModal } from "../../components/modals/imperativeModal";
import { FullScreenModal } from "../../components/modals/getFullscreenModal";
import { useSystemStatus } from "../../hooks/useSystemStatus";
import { ActionButtonDropdown } from "../../components/ActionButtonDropdown";
import { MoreHorizontal } from "react-feather";

export default function OrgInvoices() {
  const params: any = useParams();
  const orgId = params.orgId;

  const { orgInfo, isLoading } = useAllOrgInfo({ orgId });

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isLoading ? (
          <CenteredLoader />
        ) : orgInfo ? (
          <OrgInvoicesInner org={orgInfo.org} orgSecret={orgInfo.orgSecret} orgSettings={orgInfo.orgSettings} />
        ) : (
          <Typography>{translate({ defaultMessage: "Failed to load invoices" })}</Typography>
        )}
      </View>
    </Box>
  );
}

function OrgInvoicesInner(p: { org: Org; orgSecret: OrgSecret | null; orgSettings: OrgSettings }) {
  const [refreshCounter, setRefreshCounter] = useState(0);
  const asyncFancyTableRef = useRef<AsyncFancyTableMethods<ParentInvoiceData>>(null);

  return (
    <div style={{ flex: 1 }}>
      <div className="flex">
        <h1 className="flex-1 text-2xl sm:text-4xl mt-4">{translate.common.Invoices}</h1>

        {p.org.accounts[getCurrentUserAccountId()]?.permissions.manageFinances ? (
          <ActionButtonDropdown
            style={{ marginLeft: 12 }}
            color="secondary"
            actions={[
              {
                key: "new-coupon",
                label: () => translate({ defaultMessage: "Create New Invoice" }),
                onClick: async () => {
                  if (!p.orgSecret?.nmiConfig) {
                    openPaymentsNotAvailableYetModal();
                    return;
                  }
                  const didWrite = await openOrgInvoiceAddEditModal({
                    org: p.org,
                    type: "create",
                    initialOrgInvoice: {
                      type: OrgInvoiceTypes.manual
                    }
                  });
                  if (didWrite) {
                    setTimeout(() => {
                      setRefreshCounter(a => a + 1);
                    }, 5000);
                  }
                }
              },
              {
                key: "csv-export",
                label: () =>
                  translate(
                    { defaultMessage: "Export {num} Records to CSV" },
                    { num: asyncFancyTableRef.current?.getCurrentNumRecords() || 0 }
                  ),
                onClick: () => {
                  asyncFancyTableRef.current?.downloadCurrentDataToCSV("invoices.csv");
                }
              }
            ]}
          >
            <span style={{ fontWeight: "bold", marginRight: 6 }}>{translate({ defaultMessage: "Actions" })}</span>
            <SvgIcon style={{ paddingRight: 6 }}>
              <MoreHorizontal />
            </SvgIcon>
          </ActionButtonDropdown>
        ) : null}
      </div>
      <AsyncFancyTable
        methodsRef={asyncFancyTableRef}
        pagination={{ initialPageSize: 50, pageSizes: [50, 100, 150] }}
        getRowOptions={(item: ParentInvoiceData) => {
          return {
            href: `/app/org/${item.org_id}/invoices/${item.id}`
          };
        }}
        className="mb-10"
        getRowKey={item => item.id}
        dataCachingKey={"invoices-" + p.org.id}
        noItemsMessage={translate({ defaultMessage: "No invoices created yet..." })}
        noFilteredItemsMessage={translate({ defaultMessage: "No invoices matching selected filters..." })}
        fetchItems={(info): Promise<{ itemsToBeRendered: ParentInvoiceData[]; totalNumberOfItemsMatchingCriteria: number }> => {
          return getBifrost()
            .orgInvoice__server__getParentOrgInvoicesForOrg.fetchServer({
              orgId: p.org.id,
              searchTerm: info.filters?.search,
              selfAccountId: getCurrentUserAccountId(),
              status: info.filters?.status,
              dueDateStartMS: info.filters?.dueDateStartMS,
              dueDateEndMS: info.filters?.dueDateEndMS,
              offset: info.pagination.numItemsPerPage * (info.pagination.page - 1),
              sort: info.sort as any,
              limit: info.pagination.numItemsPerPage
            })
            .then(a => {
              return {
                itemsToBeRendered: a.data.data,
                totalNumberOfItemsMatchingCriteria: a.data.count
              };
            });
        }}
        extraDeps={[refreshCounter, p.org.id]}
        columns={{
          id: {
            label: translate.common.ID,
            getCell(item) {
              return item.id;
            }
          },
          player_name: {
            label: translate.common.Player,
            getCell(item) {
              return <div>{item.player_name}</div>;
            },
            sortable: true
          },
          total_invoiced: {
            label: translate.common.Total,
            getCell(item) {
              return formatMoneyCentsToDollarCentPrettyString(parseInt(item.total_invoiced));
            },
            sortable: true
          },
          total_paid: {
            label: translate.common.Paid,
            getCell(item) {
              return formatMoneyCentsToDollarCentPrettyString(parseInt(item.total_paid));
            },
            sortable: true
          },
          total_balance_remaining: {
            label: translate({ defaultMessage: "Balance", description: "As in, balance remaining to be paid" }),
            getCell(item) {
              return formatMoneyCentsToDollarCentPrettyString(parseInt(item.total_balance_remaining));
            },
            sortable: true
          },

          created_at_ms: {
            label: translate({ defaultMessage: "Issued" }),
            getCell(item) {
              return dateFormatters.mm_dd_yyyy(moment(parseInt(item.created_at_ms)).toDate(), getCurrentLocale());
            },
            sortable: true
          },

          issue_by: {
            label: translate({ defaultMessage: "Issued By" }),
            getCell(item) {
              return item.assigned_by_first_name + " " + item.assigned_by_last_name;
            },
            sortable: true
          },
          due_date_ms: {
            label: translate.common.DueDate,
            getCell(item) {
              return dateFormatters.mm_dd_yyyy(moment(parseInt(item.due_date_ms)).toDate(), getCurrentLocale());
            },
            sortable: true
          },
          memo: {
            label: translate.common.Memo,
            getCell(item) {
              return item.memo;
            },
            sortable: true
          },
          status: {
            label: translate.common.Status,
            getCell(item) {
              const text = PRETTY_OVERALL_ORG_INVOICE_STATUS(getCurrentLocale())[item.status];

              return (
                <span
                  className={twMerge(
                    (item.status === OverallInvoiceStatus.late ||
                      item.status === OverallInvoiceStatus.latePaymentInstallment ||
                      item.status === OverallInvoiceStatus.invoiceFailedPayment) &&
                      "text-red-500 font-bold"
                  )}
                >
                  {text}
                </span>
              );
            }
          }
        }}
        buttons={
          compute.org.hasOrgPermission({
            accountId: getCurrentUserAccountId(),
            org: p.org,
            permission: ORG_PERMISSIONS.manageFinances
          })
            ? [
                //Edit
                {
                  icon: () => <PencilIcon color={COLORS.blue_66} />,
                  onClick: async item => {
                    openOrgInvoiceAddEditModal({
                      org: p.org,
                      type: "edit",
                      initialOrgInvoice: item.item
                    });
                  },

                  isVisible: item => item.status !== OverallInvoiceStatus.paid
                },
                //Delete
                {
                  icon: item => {
                    if (!parseInt(item.total_balance_remaining)) {
                      return <></>;
                    }
                    return <TrashIcon color={COLORS.red_66} />;
                  },
                  isVisible: item => item.status !== OverallInvoiceStatus.paid,
                  async onClick(item) {
                    const orgInvoice = item.item;

                    if (orgInvoice.thisInvoicePaidInFullDateMS) {
                      // Cannot delete, can only forgive
                      await openPaymentForgivenessModal({
                        orgInvoice,
                        remainingAmount: parseInt(item.total_balance_remaining),
                        forgiveChildren: true,
                        async onComplete() {
                          wrapPromiseWithLoader(
                            new Promise(res => {
                              setTimeout(() => {
                                setRefreshCounter(refreshCounter + 1);
                                res(null);
                              }, 2000);
                            })
                          );
                        }
                      });
                    } else {
                      const confirm = await getConfirm({
                        confirmButtonColor: "red",
                        subtitle: translate({ defaultMessage: "Are you sure you want to delete this invoice?" }),
                        title: translate({ defaultMessage: "Delete Invoice?" })
                      });
                      if (confirm) {
                        await getBifrost().orgInvoice__client__deleteOrgInvoice.fetchClient({
                          id: orgInvoice.id,
                          orgId: p.org.id
                        });
                        wrapPromiseWithLoader(
                          new Promise(res => {
                            setTimeout(() => {
                              setRefreshCounter(refreshCounter + 1);
                              res(null);
                            }, 2000);
                          })
                        );
                      }
                    }
                  }
                }
              ]
            : []
        }
        renderFiltersWrapper={filters => <div className="flex flex-wrap gap-1.5 mt-4 mb-2">{filters}</div>}
        renderFilters={{
          search: a => (
            <CoolTextInput
              style={{ width: 300, marginBottom: 8 }}
              value={a.valueImmediate}
              placeholder={translate({ defaultMessage: "Search invoice id, player, or memo..." })}
              onChange={newVal => {
                if (newVal) {
                  a.setValueDebounced(newVal, 500, { leading: false, trailing: true });
                } else {
                  a.setValue("");
                }
              }}
            />
          ),
          dueDateStartMS: a => (
            <CoolDateInput
              className="mb-2"
              value={a.value ? moment(a.value).toDate() : undefined}
              placeholderText={translate({ defaultMessage: "Due Date Start" })}
              isClearable
              onChange={newVal => {
                a.setValue(moment(newVal).startOf("day").valueOf());
              }}
            />
          ),
          dueDateEndMS: a => (
            <CoolDateInput
              className="mb-2"
              value={a.value ? moment(a.value).toDate() : undefined}
              placeholderText={translate({ defaultMessage: "Due Date End" })}
              isClearable
              onChange={newVal => {
                a.setValue(moment(newVal).endOf("day").valueOf());
              }}
            />
          ),
          status: a => {
            return (
              <CoolMultiSelectInput
                options={ObjectKeys(PRETTY_OVERALL_ORG_INVOICE_STATUS(getCurrentLocale())).map(a => ({
                  label: PRETTY_OVERALL_ORG_INVOICE_STATUS(getCurrentLocale())[a],
                  value: a
                }))}
                containerClassName="min-w-[150px]"
                value={a.value?.map(b => ({ label: PRETTY_OVERALL_ORG_INVOICE_STATUS(getCurrentLocale())[b], value: b }))}
                placeholder={translate.common.Status}
                onChange={newVal => {
                  a.setValue(newVal.length ? (newVal.map(a => a.value) as OverallInvoiceStatus[]) : undefined);
                }}
              />
            );
          }
        }}
        initialFilterValues={{
          search: "",
          dueDateStartMS: undefined as number | undefined,
          dueDateEndMS: undefined as number | undefined,
          status: undefined as OverallInvoiceStatus[] | undefined
        }}
      />
    </div>
  );
}
function openPaymentsNotAvailableYetModal() {
  const modal = openModal({
    body: (
      <FullScreenModal
        onRequestDismiss={() => {
          modal.close();
        }}
        title={translate({ defaultMessage: "Contact Us To Use Invoices" })}
        bottomButton={{
          title: translate({ defaultMessage: "OK" }),
          onPress: () => {
            modal.close();
          }
        }}
      >
        <div>
          {translate({
            defaultMessage:
              "Before you can create invoices, we need to get your club payment account set up so that you can receive payments. Please contact us at support@olliesports.com to get started using invoices!"
          })}
        </div>
      </FullScreenModal>
    ),
    uniquenessId: "cant-create-invoice-no-org-secret-modal"
  });
}
