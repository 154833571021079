import { translate } from "@ollie-sports/i18n";
import { PrettyCoolTextInputWithLabel, PrettyCoolTextAreaWithLabel, Form, FormRef } from "../../../components/Form";
import { CoolCheckboxInput } from "../../../components/Inputs/CoolCheckboxInput";
import { LINK_REGEX, EMAIL_REGEX } from "../../../utils/Regexes";
import { OrgSettings } from "@ollie-sports/models";
import { useImmutableState } from "../../../utils/useImmutableState";
import { useRef } from "react";
import { Button } from "@material-ui/core";
import { common__magicDeleteValue } from "@ollie-sports/core/dist/api";
import { getBifrost } from "../../../services/bifrost.service";
import { openErrorToast } from "../../../utils/openErrorToast";
import { InfoTooltipIcon } from "../../../components/InfoTooltip";
import { StyledButton } from "../../../components/StyledButton";
import { Prompt } from "react-router";
import { dequal } from "dequal";
import { useOrgSettings } from "../../../hooks/useOrgSettings";

export function OrgRegistrationSettings(p: { orgId: string; initialOrgSettings: OrgSettings }) {
  const [orgSettings, setOrgSettings] = useImmutableState<OrgSettings>(p.initialOrgSettings);
  const { orgSettings: subscribedOrgSettings } = useOrgSettings({ orgId: p.orgId });
  const formRef = useRef<FormRef>(null);

  return (
    <Form ref={formRef}>
      {() => (
        <>
          <Prompt
            message={translate({ defaultMessage: "Are you sure you wish to navigate? You have unsaved changes." })}
            when={!dequal(subscribedOrgSettings, orgSettings)}
          />

          <PrettyCoolTextInputWithLabel
            label={translate({ defaultMessage: "Default Registration Reply Email" })}
            infoTooltip={translate({
              defaultMessage:
                'The default "reply to" email address members see when receiving emails regarding their registration status, such as past due payments.'
            })}
            style={{ marginTop: 16 }}
            onChange={newVal => {
              if (!newVal) {
                setOrgSettings({
                  registrationSettings: {
                    ...orgSettings.registrationSettings,
                    defaultReplyToEmailAddress: undefined
                  }
                });
                return;
              }
              setOrgSettings({
                registrationSettings: {
                  ...orgSettings.registrationSettings,
                  defaultReplyToEmailAddress: newVal?.toLowerCase() ?? undefined
                }
              });
            }}
            validate={v => {
              if (v) {
                if (!v.match(EMAIL_REGEX)) {
                  return translate({ defaultMessage: "Must be a valid email" });
                }
              }
              return "";
            }}
            inputProps={{ className: "sm:max-w-xs", placeholder: translate({ defaultMessage: "Enter Email Address" }) }}
            value={orgSettings.registrationSettings?.defaultReplyToEmailAddress ?? ""}
          />

          <StyledButton
            className="mt-4"
            text={translate.common.Save}
            onClick={async () => {
              try {
                await getBifrost().org__server__updateOrgSettings.fetchServer({
                  id: p.initialOrgSettings.id,
                  orgSettings: {
                    registrationSettings: {
                      defaultReplyToEmailAddress:
                        orgSettings.registrationSettings?.defaultReplyToEmailAddress ?? common__magicDeleteValue()
                    }
                  }
                });
              } catch (e) {
                openErrorToast(
                  translate({
                    defaultMessage:
                      "There was a problem updating the settings. Please try again or contact support@olliesports.com"
                  })
                );
              }
            }}
          />
        </>
      )}
    </Form>
  );
}
