import { Org } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import {
  validateUserIdentity,
  validateSelfAccountId,
  validateTokenAndEnsureSelfAccountIdMatches
} from "../../internal-utils/server-auth";

export function org__server__getUserAdminOrgs(p: { userAccountId: string }): Promise<Org[]> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return h.Org.query({
    where: [
      {
        accounts: { [p.userAccountId]: { exists: ["==", true] } }
      }
    ]
  }).then(a => a.docs);
  // SERVER_ONLY_TOGGLE
}

org__server__getUserAdminOrgs.auth = (req: any) => {
  return validateUserIdentity({ propertyToCheck: "userAccountId", request: req });
};

// i18n certified - complete
