import { AccountId, Org__Account, OrgId, Team__Account, TeamId } from "@ollie-sports/models";
import * as express from "express";
import { updateDerivedForTeam } from "../../internal-utils/team-utils";
import { validateToken } from "../../internal-utils/server-auth";
import { getUniversalHelpers } from "../../helpers";

export async function org__server__setOrgAccount(p: { orgId: OrgId; accountId: AccountId; orgAccount: Org__Account }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.Org.setPath({
    id: p.orgId,
    pathObj: { accounts: { [p.accountId]: true } },
    value: { accounts: { [p.accountId]: p.orgAccount } }
  });
  // SERVER_ONLY_TOGGLE
}

org__server__setOrgAccount.auth = async (r: express.Request) => {
  // Should have manage role permission
  await validateToken(r);
};

// i18n certified - complete
