import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";

export async function org__server__setLogoUri(p: { id: string; logoUri: string }) {
  // SERVER_ONLY_TOGGLE

  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.Org.update({ id: p.id, doc: { logoUri: p.logoUri } });

  // SERVER_ONLY_TOGGLE
}

org__server__setLogoUri.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};
// i18n certified - complete
