import { getUniversalHelpers } from "../../helpers";
import { AccountId, OrgId, TeamId } from "@ollie-sports/models";
import shortid from "shortid";

const IMAGE_DIRECTORY = "org/";

export async function org__client__uploadLogo(p: { blob: any; orgId: OrgId }): Promise<string | undefined> {
  const { app, ollieFirestoreV2: h } = getUniversalHelpers();

  const org = await h.Org.getDoc(p.orgId);
  if (!org) {
    return;
  }

  const uploadTask = app
    .storage()
    .ref()
    .child(IMAGE_DIRECTORY + p.orgId + "/" + shortid() + ".jpg")
    .put(p.blob);

  // tslint:disable-next-line: await-promise
  await uploadTask;

  const storageUri = await uploadTask.snapshot.ref.getDownloadURL();

  return storageUri as string;
}

// i18n certified - complete
