import { Org } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function org__server__getOrgNoAuth(p: { orgId: string }): Promise<Org | null> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const org = await h.Org.getDoc(p.orgId);
  return org;
  // SERVER_ONLY_TOGGLE
}

org__server__getOrgNoAuth.auth = async (req: any) => {};

// i18n certified - complete
