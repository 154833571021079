import { Typography, Box } from "@material-ui/core";
import { View } from "react-native-web";
import _ from "lodash";
import { Org } from "@ollie-sports/models";
import { CenteredLoader } from "../../components/CenteredLoader";
import { translate } from "@ollie-sports/i18n";
import { Link, useParams } from "react-router-dom";
import { useOrg } from "../../hooks/useOrg";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";

export default function OrgReports() {
  const params: any = useParams();
  const orgId = params.orgId;

  const { org, isLoading } = useOrg({ orgId });

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isLoading ? (
          <CenteredLoader />
        ) : org ? (
          <OrgReportsInner org={org} />
        ) : (
          <Typography>{translate({ defaultMessage: "Failed to load org" })}</Typography>
        )}
      </View>
    </Box>
  );
}

function OrgReportsInner(p: { org: Org }) {
  const reports = _.compact([
    p.org.accounts[getCurrentUserAccountId()]?.permissions.manageFinances
      ? {
          href: `/app/org/${p.org.id}/reports/registrationSummaryByTeam`,
          title: translate({ defaultMessage: "Registration Summary By Team" }),
          description: translate({
            defaultMessage: "Summarizes player registrations by team, including total amount collected, etc."
          })
        }
      : null,
    p.org.accounts[getCurrentUserAccountId()]?.permissions.manageFinances
      ? {
          href: `/app/org/${p.org.id}/reports/couponUsage`,
          title: translate({ defaultMessage: "Invoice Coupon Usage" }),
          description: translate({
            defaultMessage:
              "Summarizes coupon usage on invoices (e.g. registration invoices and manual invoices), including player name, team name, and discounts. Does not include coupon usage for tryouts and camps."
          })
        }
      : null,
    p.org.accounts[getCurrentUserAccountId()]?.permissions.manageFinances
      ? {
          href: `/app/org/${p.org.id}/reports/moneyOwed`,
          title: translate({ defaultMessage: "Money Owed" }),
          description: translate({
            defaultMessage: "Summarizes money owed including registrations and invoices."
          })
        }
      : null,
    {
      href: `/app/org/${p.org.id}/reports/teamAccountSummary`,
      title: translate({ defaultMessage: "Team Account Summary" }),
      description: translate({
        defaultMessage: "Summarizes the accounts by team."
      })
    }
  ]);

  return (
    <div className="flex-col">
      <h1 className="text-2xl sm:text-4xl mt-4 mb-6">{translate.common.Reports}</h1>
      <div className="flex flex-1 flex-wrap gap-2">
        {reports.map(r => (
          <ReportNavigateCard key={r.href} href={r.href} title={r.title} description={r.description} />
        ))}
      </div>
    </div>
  );
}

function ReportNavigateCard(p: { href: string; title: string; description: string }) {
  return (
    <div className="flex flex-col p-4 h-60 rounded-lg border border-gray-500 bg-white w-2/4 max-w-sm">
      <h2 className="font-bold text-2xl">{p.title}</h2>
      <div className="py-2 flex flex-1 items-center text-lg leading-tight">{p.description}</div>
      <Link to={p.href} className="text-blue-500 text-lg">
        {translate({ defaultMessage: "View Report" })}
      </Link>
    </div>
  );
}
