import { AccountId, Org, OrgSettings } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import express from "express";

export async function org__server__updateOrg(p: {
  id: string;
  org: Partial<Org>;
  orgSettings: Partial<OrgSettings>;
  selfAccountId: AccountId;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const [org, orgSettings] = await Promise.all([h.Org.getDoc(p.id), h.OrgSettings.getDoc(p.id)]);
  if (!org || !orgSettings || !org.accounts[p.selfAccountId]?.exists || org.id !== orgSettings.id) {
    throw new Error("This account does not have permission to edit this org");
  }

  await Promise.all([h.OrgSettings.update({ id: p.id, doc: p.orgSettings }), h.Org.update({ id: p.id, doc: p.org })]);

  // SERVER_ONLY_TOGGLE
}

org__server__updateOrg.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};
// i18n certified - complete
