import { getOrgRegistrationAnswerStringOrArray } from "@ollie-sports/core";
import { translate, getCurrentLocale, dateFormatters } from "@ollie-sports/i18n";
import { OrgRegistrationAnswer, OrgRegistrationQuestionType } from "@ollie-sports/models";
import { isArray } from "lodash";
import { View } from "react-native-web";
import CoolerTable from "../../../components/CoolerTable";
import { StyledText } from "../../../components/StyledText";
import { TableSectionWrapper } from "./TableSectionWrapper";
import moment from "moment";
import { cleanlyCutString } from "../../../utils/cleanlyCutString";
import { openSuccessToast } from "../../../utils/openErrorToast";
import _ from "lodash";

export function OrgRegistrationAnswersTable(p: { orgRegistrationAnswers: OrgRegistrationAnswer[] }) {
  return (
    <TableSectionWrapper title={translate({ defaultMessage: "Registration Responses" })}>
      <CoolerTable
        items={_(p.orgRegistrationAnswers)
          .map(a => getAnswerDetailsForDisplay(a))
          .orderBy(a => a.id)
          .value()}
        getItemKey={item => item.id}
        columnDefs={[
          {
            getValue(item) {
              return item.titleString;
            },
            label: translate.common.Title
          },
          {
            getValue(item) {
              const isLegalDoc = item.orig.questionSnapshot.type === OrgRegistrationQuestionType.legalDoc;
              return (
                <div
                  className={isLegalDoc ? "cursor-help" : undefined}
                  onClick={
                    isLegalDoc
                      ? () => {
                          navigator.clipboard.writeText((item.orig.questionSnapshot as any).textMD);
                          openSuccessToast(translate({ defaultMessage: "Copied!" }));
                        }
                      : undefined
                  }
                  title={isLegalDoc ? (item.orig.questionSnapshot as any).textMD : undefined}
                >
                  {item.questionString}
                </div>
              );
            },
            label: translate.common.Question
          },
          {
            getValue(item) {
              return item.answerString;
            },
            label: translate.common.Answer
          }
        ]}
      />
    </TableSectionWrapper>
  );
}

function getAnswerDetailsForDisplay(registrationAnswer: OrgRegistrationAnswer) {
  let titleString = "";
  let questionString = "";
  let answerString = "";
  let id = registrationAnswer.id;
  const questionSnapshot = registrationAnswer.questionSnapshot;
  switch (questionSnapshot.type) {
    case OrgRegistrationQuestionType.explainer:
      break;
    case OrgRegistrationQuestionType.legalDoc:
      if (registrationAnswer.type === OrgRegistrationQuestionType.legalDoc) {
        titleString = questionSnapshot.shortTitle;
        questionString = cleanlyCutString(questionSnapshot.textMD, 100);
        answerString = `${translate(
          { defaultMessage: "Agreed on {date}" },
          { date: dateFormatters.mm_dd_yyyy(moment(registrationAnswer.agreedToAtMS).toDate(), getCurrentLocale()) }
        )}`;
      }
      break;
    case OrgRegistrationQuestionType.radio:
      if (registrationAnswer.type === OrgRegistrationQuestionType.radio) {
        titleString = questionSnapshot.shortTitle;
        questionString = questionSnapshot.questionMD;
        answerString = registrationAnswer.selection;
      }
      break;
    case OrgRegistrationQuestionType.checkbox:
      if (registrationAnswer.type === OrgRegistrationQuestionType.checkbox) {
        titleString = questionSnapshot.shortTitle;
        questionString = questionSnapshot.questionMD;
        answerString = registrationAnswer.selections.join(",");
      }
      break;
    case OrgRegistrationQuestionType.freeResponse:
      if (registrationAnswer.type === OrgRegistrationQuestionType.freeResponse) {
        titleString = questionSnapshot.shortTitle;
        questionString = questionSnapshot.questionMD;
        answerString = registrationAnswer.response;
      }
  }
  return { id, orig: registrationAnswer, titleString, questionString, answerString };
}
