import { AccountId, OrgId, OrgSeasonId, PlayerBundleId } from "@ollie-sports/models";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";
import { OrgMemberDetailsData } from "../../constants/Misc";
import { getOrgMemberDetailsData } from "../../utils/member-utils";

export async function org__server__getOrgMemberDetailsData(
  p: { orgId: OrgId; currentOrgSeasonIds?: OrgSeasonId[] } & (
    | { type: "account"; accountId: AccountId }
    | { type: "playerBundle"; playerBundleId: PlayerBundleId; ignoreFamilyMembers?: boolean }
  )
): Promise<OrgMemberDetailsData | null> {
  return getOrgMemberDetailsData(p);
}

org__server__getOrgMemberDetailsData.auth = async (r: express.Request) => {
  // Make sure user has correct permission
  await validateToken(r);
};

// i18n certified - complete
